import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { DialogConfig } from 'app/components/dialog/dialog-config'
import { DialogRef } from 'app/components/dialog/dialog-ref'
import { DialogService } from 'app/components/dialog/dialog.service'
import { AlertVariant } from 'app/components/uicomponents/alert/alert.component'
import { AnnotationsVariant } from 'app/components/uicomponents/annotations/annotations.component'
import { ashtmaNavigator } from 'app/interfaces/theme.interface'
import { AnalyticsService, Event } from 'app/services/analytics'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { NavigationService } from 'app/services/navigation.service'
import { ThemeService } from 'app/services/theme.service'
import { UtilitiesService } from 'app/services/utilities.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { logger } from 'app/utils/logger'
import { NO, YES } from 'app/utils/types'

type ReferralLeterCTAType = 'main' | 'manual'

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  animations: FadeInAnimation.animations
})
export class AssessmentComponent implements OnInit, AfterViewInit, OnDestroy {
  private static readonly TAG = 'comp::Assessment'

  @Input() public data: PnPage
  @Input() public context: any

  public annotationsVariant = AnnotationsVariant
  public alertVariant = AlertVariant
  public patientID: string
  public actions: { }
  public title: string
  public outcomes: []
  public refer: boolean
  public endToEnd: { }
  public importantBox: { }
  public accordions: []
  public notes: { }
  public guidance: null
  public endToEndRI: null
  public extendedAlert: null
  private showSecondaryCare: boolean = true
  private secondaryCareDialog: DialogRef = null
  private endConsultationDialog: DialogRef = null
  private referralLetterDialog: DialogRef = null
  private reportDialog: DialogRef = null
  public tool: string
  public additionalNote = null
  public secondaryCare = null
  public validCountryForRI = null

  constructor(
    private communicationService: CommunicationService,
    private dialog: DialogService,
    public translate: TranslateService,
    private navigationService: NavigationService,
    private themeService: ThemeService,
    private utilService: UtilitiesService,
    private analyticsService: AnalyticsService,
    private localDataService: LocalDataService,
    private router: Router) {
  }

  public ngOnInit() {
    this.secondaryCare = this.utilService.getSecondaryCare()
    this.additionalNote = this.utilService.getAssessmentNote()
    const theme = this.themeService.getActiveTheme()
    const isRIPlus = theme && theme.name === ashtmaNavigator.name || false
    if (!isRIPlus) {
      this.checkCountryForRIPlus()
    }
    this.showSecondaryCare = !this.secondaryCare && isRIPlus
  }

  public async ngAfterViewInit() {
    if (this.showSecondaryCare) {
      this.initSecondaryCareQuestion()
    } else {
      logger.info('%s - ngAfterViewInit() getting the assessment', AssessmentComponent.TAG)
      this.getAssessments()
    }
  }

  public async checkCountryForRIPlus() {
    const { country } = this.localDataService.getLocale()
    let countryConfig: any
    countryConfig = await this.communicationService.getToolForCountry(country.toLowerCase())
    this.validCountryForRI = countryConfig.indexOf(ashtmaNavigator.name) !== -1
  }

  public secondaryCareYes() {
    logger.info('%s - secondaryCareYes()', AssessmentComponent.TAG)
    this.secondaryCare = YES
    this.storeSecondaryCare()
  }

  public secondaryCareNo() {
    logger.info('%s - secondaryCareNo()', AssessmentComponent.TAG)
    this.secondaryCare = NO
    this.storeSecondaryCare()
  }

  private storeSecondaryCare() {
    this.utilService.setSecondaryCare(this.secondaryCare)
    logger.info('%s - storeSecondaryCare() getting the assessment', AssessmentComponent.TAG)
    this.getAssessments()
    this.secondaryCareDialog.close()
    this.secondaryCareDialog = null
  }

  public goToStartConsultation() {
    this.navigationService.resetContext()
    this.router.navigate(['/start-consultation'])
  }

  public actionHandler(item) {
    if (item.action.toLowerCase() === 'download') {
      this.report()
      // this.downloadReport({ ...this.getAssessmentContext(), note: this.additionalNote || '' })
      // this.analyticsService.event(Event.MgmtPlanDowloadReport, { value1: !!this.additionalNote })
    } else if (item.action.toLowerCase() === 'create') {
      this.referralLetter('main')
    }
  }

  private report() {
    const config = new DialogConfig<any>()
    config.backDrop = true
    config.title = this.translate.instant('assessment.reportTitle')
    config.subTitle = this.translate.instant('assessment.reportContent')
    config.content = this.translate.instant('assessment.popupNote')
    config.doneLabel = this.translate.instant('assessment.reportContinue')
    config.cancelLabel = this.translate.instant('assessment.reportCancel')
    this.reportDialog = this.dialog.open(null, config)
    this.reportDialog.done.subscribe(() => {
      this.reportDialog.close()
      this.downloadReport({ ...this.getAssessmentContext(), note: this.additionalNote || '' })
      this.analyticsService.event(Event.MgmtPlanDowloadReport, { value1: !!this.additionalNote })
      this.reportDialog = null
    })
    this.reportDialog.cancel.subscribe(() => {
      this.reportDialog.close()
    })
  }

  public referralLetter(cta: ReferralLeterCTAType) {
    const config = new DialogConfig<any>()
    config.backDrop = true
    config.title = this.translate.instant('assessment.referralLetterTitle')
    config.subTitle = this.translate.instant('assessment.referralLetterContent')
    config.content = this.translate.instant('assessment.popupNote')
    config.doneLabel = this.translate.instant('assessment.referralLetterContinue')
    config.cancelLabel = this.translate.instant('assessment.referralLetterCancel')
    this.referralLetterDialog = this.dialog.open(null, config)
    this.referralLetterDialog.done.subscribe(() => {
      this.referralLetterDialog.close()
      this.communicationService.downloadReferralLetter({ context: this.context })
      if (cta === 'main') {
        this.analyticsService.event(Event.MgmtPlanDowloadReferralLetter)
      } else if (cta === 'manual') {
        this.analyticsService.event(Event.MgmtPlanDowloadReferralLetterManual, { value1: this.refer ? 'yes' : 'no' })
      } else {
        throw new Error('Unkown CTA Type: ' + cta)
      }
      this.referralLetterDialog = null
    })
    this.referralLetterDialog.cancel.subscribe(() => {
      this.referralLetterDialog.close()
    })
  }

  public jumpToGuidance(link) {
    this.utilService.setGuidanceSection(link)
    this.navigationService.jumpTo(link.direction)
  }

  public jumpEndToEnd(cta) {
    this.navigationService.jumpTo(cta.direction)
  }

  public goToRIPlus() {
    this.navigationService.resetContext()
    this.localDataService.setLastActiveTool(ashtmaNavigator.name)
    this.router.navigate(['/login'])
    this.analyticsService.event(Event.UpgradeToPlus)
  }

  public onEndConsultation() {
    const config = new DialogConfig<any>()
    config.backDrop = false
    config.title = this.translate.instant('assessment.exitConsultationTitle')
    config.subTitle = this.translate.instant('assessment.exitConsultationContent')
    config.doneLabel = this.translate.instant('assessment.exitConsultationYes')
    config.cancelLabel = this.translate.instant('assessment.exitConsultationNo')
    this.endConsultationDialog = this.dialog.open(null, config)
    this.endConsultationDialog.done.subscribe(() => {
      this.endConsultationDialog.close()
      this.endConsultationDialog = null
      this.goToStartConsultation()
    })
    this.endConsultationDialog.cancel.subscribe(() => {
      this.endConsultationDialog.close()
    })
  }

  private initSecondaryCareQuestion() {
    const config = new DialogConfig<any>()
    config.backDrop = false
    config.noPrimary = true
    config.title = this.translate.instant('assessment.secondaryCareTitle')
    config.subTitle = this.translate.instant('assessment.secondaryCareSubTitle')
    config.doneLabel = this.translate.instant('assessment.secondaryCareYes')
    config.cancelLabel = this.translate.instant('assessment.secondaryCareNo')
    this.secondaryCareDialog = this.dialog.open(null, config)
    this.secondaryCareDialog.done.subscribe(() => this.secondaryCareYes())
    this.secondaryCareDialog.cancel.subscribe(() => this.secondaryCareNo())
  }

  private getAssessmentContext() {
    if (this.secondaryCare) {
      return {
        context: this.context,
        secondaryCare: this.secondaryCare
      }
    } else {
      return { context: this.context }
    }
  }

  private getAssessments() {
    const ctx = this.getAssessmentContext()
    this.communicationService.getAssessment(ctx).subscribe(response => {
      const {
        patientID, actions, outcomes, extendedAlert,
        endToEnd, importantBox, accordions, notes, guidance, endToEndRI
      } = response.body
      this.patientID = patientID
      this.actions = actions
      this.endToEnd = endToEnd || null
      this.importantBox = importantBox
      this.accordions = accordions
      this.outcomes = outcomes
      this.notes = notes
      this.guidance = guidance
      this.endToEndRI = endToEndRI
      this.extendedAlert = extendedAlert
      this.refer = outcomes[0].icon === 'refer'
      this.analyticsService.event(Event.MgmtPlan, { value1: this.refer ? 'yes' : 'no' })
    })
  }

  private downloadReport(ctx) {
    this.communicationService.downloadReport(ctx)
  }

  public onPanelToggled(opened) {
    if (opened) {
      this.analyticsService.event(Event.MgmtPlanOpenAssessmentAccordion)
    }
  }

  public ngOnDestroy() {
    this.utilService.setAssessmentNote(this.additionalNote)
  }
}
