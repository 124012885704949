import { Injectable, OnDestroy } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { UtilitiesService } from 'app/services/utilities.service'
import * as _ from 'lodash'
import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
/* Communication Service */
import { CommunicationService } from './communication.service'
import { LocalDataService } from './local-data.service'

@Injectable()
export class NavigationService implements OnDestroy {

  private _localOutput = { }

  get localOutput() {
    return this._localOutput
  }

  set localOutput(value) {
    this._localOutput = value
  }

  private stepSource = new BehaviorSubject<any>({ })
  private contextSource = new BehaviorSubject<any>({ })
  private unsubscribe$ = new Subject()

  // tslint:disable-next-line: member-ordering
  public context = this.contextSource.asObservable()
  // localContext is as a copy of contextSource to be used within the service
  private localContext: any = { }
  // tslint:disable-next-line: member-ordering
  public step = this.stepSource.asObservable()
  // localStep is as a copy of stepSource to be used within the service
  private _localStep: any = { }

  get localStep() {
    return this._localStep
  }

  set localStep(value) {
    this._localStep = value
  }

  constructor(
    private communicate: CommunicationService,
    private localData: LocalDataService,
    private titleService: Title,
    private utils: UtilitiesService,
    private router: Router
  ) {
  }

  public jumpTo(id: string, data?: any): void {
    if (!data) data = { }
    data.context = this.localContext
    this.communicate.getPage(data, id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
      this.setStep(response.step)
      this.setContext(response.context)
    }
    )
  }

  public getTitle(): string | null {
    if (!!this.localStep && !!this.localStep.header && !!this.localStep.header.title) return this.localStep.header.title
    return null
  }

  public nextPage(data) {
    data.context = this.localContext
    this.communicate.getPage(data, 'next')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
      this.setStep(response.step)
      this.setContext(response.context)
    }
    )
  }

  public prevPage(data) {
    data.answer = {
      toStepId: this.localStep.header.id
    }
    data.context = this.localContext
    this.communicate.getPage(data, 'prev').subscribe(response => {
      this.setStep(response.step)
      this.setContext(response.context)
    }
    )
  }

  public reloadContent() {
    for (const property in this.localOutput) {
      if (this.localOutput[property] === null ||
        Object.entries(this.localOutput).length === 0 && this.localOutput.constructor === Object) {
        delete this.localOutput[property]
      }
    }
    const data = {
      answer: {
        toStepId: this.localStep.header.id,
        inputs: this.localOutput
      },
      context: this.localContext
    }

    this.communicate.reloadPartialStep(data, this.localStep.header.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
      this.setStep(response.step)
      this.setContext(response.context)
    }
    )
  }

  public async login(username: string, password: string) {
    try {
      await this.communicate.login(username, password)
      const { user, role, guideline, lungFunction, language } = await this.communicate.getUserSettings()
      const { config } = await this.communicate.getCountryConfig()
      const userCountry = config.find(item => item.code.toLocaleLowerCase() === user.countryCode.toLocaleLowerCase())
      const foundUserLanguage = userCountry.languages.find(item => item.code === language)
      if (!foundUserLanguage) {
        this.localData.setLocale({ country: userCountry.code, language: userCountry.defaultLang })
      }
      this.localData.setUserDetail({ ...user, role })
      if (guideline && lungFunction && lungFunction.toLocaleLowerCase() !== 'none' && role) {
        await this.router.navigate(['/start-consultation'])
      } else {
        this.utils.setWelcomeActive()
        await this.router.navigate(['/welcome'])
      }
    } catch (e) {
      // we need to make sure we log out as we may have partial success of login but fail on settings
      this.logout()
      throw e
    }
  }

  public async startConsultation(data?) {
    if (!data) data = { }
    const res = await this.communicate.startConsultation(data)
    this.setContext(res.context)
    this.nextPage({ })
    history.replaceState({ navigationId: history.state.navigationId, sessionStart: true },
      null, window.location.pathname)
    await this.router.navigate(['questionnaire'])
  }

  public logout() {
    this.contextSource.next(null)
    this.localContext = null
    this.stepSource.next(null)
    this.localStep = null
    this.localData.clearRecentInhalers()
    this.localData.clearUserDetail()
    this.localData.clearEdifactAddress()
    this.utils.clearWelcomeActive()
    this.communicate.logout()
  }

  private setContext(context) {
    this.contextSource.next(context)
    this.localContext = context
  }

  public getContext() {
    //simple deep copy, optimized in most browsers
    return JSON.parse(JSON.stringify(this.localContext));
  }

  private setStep(step) {
    this.localStep = step
    if (step && step.header && step.header.title) {
      this.titleService.setTitle(step.header.title)
    }
    this.stepSource.next(step)
    this.localStep = step
  }

  public resetContext() {
    this.contextSource.next(null)
    this.stepSource.next(null)
    this.localContext = null
    this.localStep = null
  }

  public ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
