import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-question-textarea',
  templateUrl: './question-textarea.component.html',
  styleUrls: ['./question-textarea.component.scss']
})
export class QuestionTextareaComponent {
  @Input() public control
  @Input() public formSubmitAttempt
  @Input() public field
  @Input() public lastQuestion = false
  @Input() public index: number
  @Output() public readonly scrollToNext = new EventEmitter<any>()
  @Output() public readonly infoToggled = new EventEmitter<any>()
}
