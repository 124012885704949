import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ThemeService } from 'app/services/theme.service'
import { UtilitiesService } from 'app/services/utilities.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() public readonly showSideMenu = new EventEmitter<any>()
  public activeTheme: string
  constructor(
    private themeService: ThemeService
  ) {
  }

  public ngOnInit() {
    this.themeService.theme().subscribe( name => {
      this.activeTheme = name
    })
  }

  public onBurgerClick() {
    this.showSideMenu.emit()
  }
}
