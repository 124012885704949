import { AbstractControl, ValidatorFn } from '@angular/forms'

export function checkboxValidator(options): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const res = options.filter(e => e.checked === true)
    if (control.value || !control.value && res.length > 1 ) {
      return null
    }
    return { validCheckbox: false }
  }
}
