import { ApplicationRef, Injectable } from '@angular/core'
import { SwUpdate } from '@angular/service-worker'
import { from, Observable, of, timer } from 'rxjs'
import { catchError, first, mapTo, switchMap, timeout } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate
  ) {
    if (this.swUpdate.isEnabled) {
      this.appRef.isStable.pipe(
        first(isStable => isStable === true),
        switchMap(() => this.swUpdate.available)
      ).subscribe(() => {
        this.swUpdate.activateUpdate().then(() => document.location.reload())
      })
    }
  }

  public checkForUpdate(): Observable<boolean> {
    // This value has to match the animation duration in splash-screen-component
    const waitFor = 1000

    if (this.swUpdate.isEnabled) {
      const available$ = this.swUpdate.available.pipe(
        mapTo(true),
        timeout(waitFor),
        catchError(() => of(false))
      )

      return from(this.swUpdate.checkForUpdate()).pipe(
        switchMap(() => available$)
      )
    }

    return timer(waitFor).pipe(mapTo(false))
  }
}
