import {Component, OnInit} from "@angular/core";
import {FadeInAnimation} from "../../utils/fade-in-animation";
import { NavBackBarVariant } from '../uicomponents/back-navigation-bar/back-navigation-bar.component'
import { Router } from '@angular/router'

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: FadeInAnimation.animations
})
export class AboutComponent implements OnInit {

  constructor(private router: Router) {
  }
  ngOnInit(): void {
  }

  public navBackBarVariant = NavBackBarVariant

  public goToStartConsultation() {
      this.router.navigate(['/start-consultation'])
  }
}
