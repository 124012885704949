import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { UtilitiesService } from '../utilities.service'

@Injectable({
  providedIn: 'root'
})
export class WelcomeGuardService implements CanActivate {

  constructor(private router: Router,
              private utilService: UtilitiesService) {
  }

  public async canActivate(): Promise<boolean> {
    const inValidRoute = this.utilService.getWelcomeActive()
    if (inValidRoute) {
      return true
    } else {
      this.router.navigate(['/login'])
      return false
    }
  }
}
