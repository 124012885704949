import { Component, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { FadeInAnimation } from "app/utils/fade-in-animation";
import { NavBackBarVariant } from "../uicomponents/back-navigation-bar/back-navigation-bar.component";
import { CommunicationService } from "app/services/communication.service";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "../dialog/dialog.service";
import { UserDetailsComponent } from "./user-details.component";
import { DialogConfig } from "../dialog/dialog-config";
import { Router } from '@angular/router'
import { LocalDataService } from "app/services/local-data.service";


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  animations: FadeInAnimation.animations
})
export class UserManagementComponent implements OnInit, OnDestroy {
  public navBackBarVariant = NavBackBarVariant
  public users = [];
  public searchText = ''

  constructor(private communication: CommunicationService,
              private translate: TranslateService,
              private dialog: DialogService,
              private router: Router) {
  }

  private async loadUsers () {
    const usersObject = await this.communication.getUsers();
    this.users = Object.entries(usersObject).filter(entry => entry[0] !== "context").map(entry => {
      return entry[1];
    });
  }

  async ngOnInit(): Promise<void> {
    this.loadUsers();
  }

  public goToStartConsultation() {
    this.router.navigate(['/start-consultation'])
  }

  public async deleteUser(email: string) {
    await this.communication.deleteUser({email});
    await this.loadUsers();
  }

  public async enableUser(email: string) {
    await this.communication.enableUser({email});
    await this.loadUsers();
  }

  public async disableUser(email: string) {
    await this.communication.disableUser({email});
    await this.loadUsers();
  }

  public newUser() {
    const config = new DialogConfig();
    config.cancelLabel = this.translate.instant('userManagement.cancel');
    config.doneLabel = this.translate.instant('userManagement.save');
    config.custom = {
      complete: new EventEmitter(),
      organizations: this.users.map(u => u.profile.organization)
    }
    this.dialog.open(UserDetailsComponent, config);
    config.custom.complete.subscribe(() => this.loadUsers());
  }

  public async editUser(user: any) {
    const config = new DialogConfig();
    config.cancelLabel = this.translate.instant('userManagement.cancel');
    config.doneLabel = this.translate.instant('userManagement.save');
    config.custom = {
      complete: new EventEmitter(),
      organizations: this.users.map(u => u.profile.organization),
      user: user
    }
    this.dialog.open(UserDetailsComponent, config);
    config.custom.complete.subscribe(() => this.loadUsers());
  }

  ngOnDestroy(): void {
  }
}
