import { DOCUMENT } from '@angular/common'
import { Component, Inject, Injector, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService, LocaleInput } from 'app/services/local-data.service'
import { ErrorService } from 'app/utils/error-handling/error.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { logger } from 'app/utils/logger'
import { AnalyticsService, Event } from '../../services/analytics'
import { ScrollService } from '../../services/scroll.service'
import { DialogConfig } from '../dialog/dialog-config'
import { DialogService } from '../dialog/dialog.service'

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  animations: FadeInAnimation.animations
})
export class OnboardingComponent implements OnInit {
  private static readonly TAG = 'comp::Onboarding'
  public langForCountry = []
  public onboardForm: FormGroup
  public formSubmitAttempt = false
  public countryConfig = []
  public termsAndConditionsUrl: string = 'https://tc.example.com'
  public privacyNoticeUrl: string = 'https://priv.example.com'
  public brand: string = ''
  public notificationHidden = true
  public notification: string = ''

  private detected: LocaleInput
  private prefill: LocaleInput

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private localData: LocalDataService,
    private dialog: DialogService,
    private analyticsService: AnalyticsService,
    private errorService: ErrorService,
    private titleService: Title,
    private communication: CommunicationService,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document) {
    logger.info('%s - constructor', OnboardingComponent.TAG)
  }

  public async ngOnInit() {
    const scrollService = this.injector.get(ScrollService)
    scrollService.unlockBody()

    this.route.queryParams
      .subscribe(params => {
        if (typeof params.notification !== 'undefined') {
          this.notification = atob(params.notification)
          if (this.notification !== '') {
            this.notificationHidden = false
          }
        }
      }
  )

    this.onboardForm = this.fb.group({
      country: ['', [
        Validators.required
      ]],
      language: ['', [
        Validators.required
      ]],
      geoLocation: ['', [
        Validators.requiredTrue
      ]],
      termsAndConditions: ['', [
        Validators.requiredTrue
      ]]
    })
    try {
      // first update config to just get the raw data (will be called again to have localised labels)
      await this.updateConfig(true)
      this.route.queryParams.subscribe(p => {
        this.prefill = p || { }
        this.tryPrefill()
        this.clearURLParameters()
      })
    } catch (e) {
     await this.errorService.handleError(e)
    }
  }

  private async updateConfig(forceAPICall?: boolean) {
    const countryConfig = await this.communication.getCountryConfig(forceAPICall)
    // getting initial config
    this.countryConfig = countryConfig.config || []
    this.detected = countryConfig.detected || { }
  }

  private async tryPrefill() {
    const { country: detectedCountry, language: detectedLanguage } = Object.assign({ }, this.detected)
    const { country: prefillCountry, language: prefillLanguage } = Object.assign({ }, this.prefill)

    logger.debug('%s - this.detected %o', OnboardingComponent.TAG, this.detected)
    logger.debug('%s - this.prefill %o', OnboardingComponent.TAG, this.prefill)

    const detectedCountryObj = this.countryConfig.find(e => e.code === detectedCountry)
    const prefillCountryObj = this.countryConfig.find(e => e.code === prefillCountry)

    logger.debug('%s - detectedCountryObj %o', OnboardingComponent.TAG, detectedCountryObj)
    logger.debug('%s - prefillCountryObj %o', OnboardingComponent.TAG, prefillCountryObj)

    const selectCountryCode = prefillCountryObj != null ? prefillCountryObj.code : (detectedCountryObj != null ? detectedCountryObj.code : null)
    if (selectCountryCode != null) {
      // if we have a prefill command that we can fulfill, we follow
      this.onboardForm.controls.country.patchValue(selectCountryCode)
      this.selectDefaultLang()
    }

    // get languages allowed for this country
    const availableLangs = this.langForCountry.map(e => e.code)
    if (availableLangs.includes(prefillLanguage)) {
      this.onboardForm.controls.language.patchValue(prefillLanguage)
    } else if (availableLangs.includes(detectedLanguage)) {
      this.onboardForm.controls.language.patchValue(detectedLanguage)
    }

    // if we ever found a language, then try to switch ngx-translate
    if (this.onboardForm.value.language != null && this.onboardForm.value.language !== '') {
      logger.info('%s - tryPrefill setting to >%s<', OnboardingComponent.TAG, this.onboardForm.value.language)
      this.onLanguageSelected()
    }

    const cookieCountry = selectCountryCode
    const cookieLanguage = this.onboardForm.value.language
    let bannerCookie = this.countryConfig[0].bannerCookie.default
    const head = document.getElementsByTagName('head')[0]
    const s = document.createElement('script')

    if (document.getElementById('bannerCookie')) {
      document.getElementById('bannerCookie').remove()
    }

    if (document.getElementById('CookieReportsBannerAZ')) {
      document.getElementById('CookieReportsBannerAZ').remove()
    }

    if (document.getElementById('CookieReportsPanel')) {
      document.getElementById('CookieReportsPanel').remove()
    }

    const cookieCountryObj = this.countryConfig.find(e => e.code === cookieCountry)
    if (cookieCountryObj && cookieCountryObj.bannerCookie[cookieLanguage]) {
      bannerCookie = cookieCountryObj.bannerCookie[cookieLanguage]
    }
    s.src = bannerCookie
    s.type = 'text/javascript'
    s.id = 'bannerCookie'
    head.appendChild(s)
  }

  public onSubmit() {
    if (this.onboardForm.valid) {
      this.localData.setLocale(this.onboardForm.value)
      this.router.navigate(['/select-tool'])
    } else {
      this.formSubmitAttempt = true
    }
  }

  private updateLangForCountry(): any {
    const selectedCountry = this.onboardForm.value.country
    logger.debug('%s - Selected %o %o', OnboardingComponent.TAG, this.countryConfig, selectedCountry)
    const countryObj = this.countryConfig.find(e => e.code === selectedCountry)
    if (!!countryObj) {
      this.langForCountry = countryObj.languages || []
      this.brand = countryObj.branding ? countryObj.branding.logo : ''
    } else {
      this.langForCountry = []
      this.brand = ''
    }
    return countryObj
  }

  private selectDefaultLang(): boolean {
    logger.debug('%s - selectDefaultLang %s ', OnboardingComponent.TAG, this.onboardForm.value.country)
    const countryObj = this.updateLangForCountry()
    this.direction(countryObj)
    // should always be true
    const isDefaultLangOK = this.langForCountry.map(e => e.code).includes(countryObj.defaultLang)
    if (isDefaultLangOK) {
      this.onboardForm.controls.language.patchValue(countryObj.defaultLang)
      return true
    }
    return false
  }

  public async onCountrySelected() {
    if (this.selectDefaultLang()) {
      this.onLanguageSelected()
    }
  }

  public async onLanguageSelected() {
    const localeS = this.onboardForm.value.language;
    logger.debug('%s - onLanguageSelected %s ', OnboardingComponent.TAG, localeS)
    this.translate.use(localeS).subscribe(e => {
      this.updateConfig()
        .then(_ => {
          const countryObj = this.updateLangForCountry()
          this.direction(countryObj)
        })
        .catch(err => this.errorService.handleError(err))
    })

    this.translate.setDefaultLang(this.onboardForm.value.language)
    if (this.onboardForm.value.language === 'ja') {
      document.body.classList.add('lang-ja')
    } else {
      document.body.classList.remove('lang-ja')
    }
    this.updateLinks()
  }

  private direction(countryObj: any) {
    const htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement
    htmlTag.dir = countryObj.languageDirection[this.onboardForm.value.language]
    const htmlBody = this.document.getElementsByTagName('body')[0]
    htmlBody.className = 'lang-' + countryObj.languageDirection[this.onboardForm.value.language]
  }

  public onLinkClicked(url) {
    this.analyticsService.event(Event.ExtLinkOpen, { value1: url })
  }

  public async updateLinks() {
    try {
      const country = this.onboardForm.value.country
      const language = this.onboardForm.value.language
      const countryConfig = (await this.communication.getCountryConfig()).config
      const countryObj = countryConfig.find(e => e.code === country)

      this.termsAndConditionsUrl = countryObj.termsAndConditionsUrl[language]
      if (this.termsAndConditionsUrl == null) {
        this.termsAndConditionsUrl = countryObj.termsAndConditionsUrl.default
      }

      this.privacyNoticeUrl = countryObj.privacyNoticeUrl[language]
      if (this.privacyNoticeUrl == null) {
        this.privacyNoticeUrl = countryObj.privacyNoticeUrl.default
      }
    } catch (e) {
      await this.errorService.handleError(e)
    }
  }

  public openDisclaimerDialog(e) {
    if ((e.target.nodeName.toUpperCase() === 'A')) {
      e.preventDefault()
      const config = new DialogConfig()
      config.backDrop = true
      config.title = this.translate.instant('dialog.disclaimer.title')
      config.subTitle = this.translate.instant('dialog.disclaimer.subtitle')
      config.content = this.translate.instant('dialog.disclaimer.content')
      config.withButtons = false
      this.dialog.open(null, config)
    }
  }

  public openPrivacyDialog(e) {
    e.preventDefault()
    const config = new DialogConfig()
    config.backDrop = true
    config.title = this.translate.instant('dialog.privacy.title')
    config.subTitle = this.translate.instant('dialog.privacy.subtitle')
    config.content = this.translate.instant('dialog.privacy.content')
    config.withButtons = false
    this.dialog.open(null, config)
  }

  private clearURLParameters() {
    // unreadable one-liner follows to clear url without refreshing
    const url = window.location.href
    const indexOfSlash = url.lastIndexOf('/')
    const chunks = url.substring(indexOfSlash + 1).split('?')
    window.history.pushState(null, this.titleService.getTitle(), '/' + chunks[0])
  }
}
