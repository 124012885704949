import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { PageInterface } from 'app/interfaces/page.interface'
import { UtilitiesService } from 'app/services/utilities.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { AnalyticsService, Event } from '../../../../services/analytics'

@Component({
  selector: 'app-guidance',
  templateUrl: './guidance.component.html',
  styleUrls: ['./guidance.component.scss'],
  animations: FadeInAnimation.animations
})
export class GuidanceComponent implements PageInterface, OnInit {
  @Input() public data: PnPage
  @Input() public context: any
  public activeSection: string = ''

  constructor(private utilService: UtilitiesService,
              private analyticsService: AnalyticsService) {
  }

  public ngOnInit() {
    const action = this.utilService.getGuidanceSection()
    this.activeSection = action && action.id
    this.utilService.clearGuidanceSection()
  }

  public panelToggle(event, id) {
    if (event) {
      this.analyticsService.event(Event.GuidanceOpenAccordion, { ref_id1: id })
    }
  }

  public linkClicked(e) {
    if ((e.target.nodeName.toUpperCase() === 'A')) {
      this.analyticsService.event(Event.ExtLinkOpen, { value1: e.target.href })
    }
  }
}
