import {
  Component,
  Input
} from '@angular/core'

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigationBar.component.html',
  styleUrls: ['./navigationBar.component.scss']
})

export class NavigationBarComponent {
  @Input() public bar: any // Needs to be PnNavigation.PnBar
  @Input() public enablePrev: boolean
  @Input() public enableNext: boolean
  @Input() public loading: boolean
  // Check if this can be fixed in a different way
  // tslint:disable-next-line: ban-types
  @Input() public change: Function
  @Input() public snapScroll = false

  public notifyClick = (event: any, to: any): void => {
    this.change(to)
  }
}
