import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  animations: FadeInAnimation.animations,
  encapsulation: ViewEncapsulation.None
})
export class PanelComponent {
  @Input() public variant: boolean = false
  @Input() public intro: any
  @Input() public title: any
  @Input() public body: any
  @Input() public icon: string
  @Output() public readonly toggle: EventEmitter<any> = new EventEmitter<any>()
  @Input() public class: string = ''
  @Input() public opened: boolean = false
  @ViewChild('panelHeading', { static: false }) public panelHeading: ElementRef<HTMLElement>
}
