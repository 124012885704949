import { Component, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { ErrorService } from 'app/utils/error-handling/error.service'

@Component({
  selector: 'app-veeva',
  templateUrl: './veeva.component.html',
  styleUrls: ['./veeva.component.scss']
})
export class VeevaComponent {

  @Input() public withGlobal: boolean = false
  @Input() public globalOnlyRI = false

  public localVeevaId: string = ''
  constructor(
    private localData: LocalDataService,
    private communication: CommunicationService,
    private errorService: ErrorService
  ) {
    if (!this.withGlobal) {
      this.configureVeevaId()
    }
  }

  private async configureVeevaId() {
    try {
      const activeTool = this.localData.getLastActiveTool()
      if (activeTool) {
        this.localVeevaId = 'localID_' + activeTool
      }
    } catch (error) {
      this.errorService.handleError(error)
    }
  }

}
