import { animate, animation, style } from '@angular/animations'

const durationMs = 400
export const transitions = {
  fadeIn: animation([
    style({ opacity: 0 }),
    animate(durationMs + 'ms', style({ opacity: 0.5 }))
  ]),
  fadeOut: animation([
    style({ opacity: 0.5 }),
    animate(durationMs + 'ms', style({ opacity: 0 }))
  ]),
  fadeInImg: animation([
    style({ opacity: 0 }),
    animate(350, style({ opacity: 1 }))
  ]),
  fadeOutImg: animation([
    style({ opacity: 1 }),
    animate(350, style({ opacity: 0 }))
  ]),
  questionFadeIn: animation([
    style({ opacity: 0.3 }),
    animate(durationMs + 'ms', style({ opacity: 1 }))
  ]),
  questionFadeOut: animation([
    style({ opacity: 1 }),
    animate(durationMs + 'ms', style({ opacity: 0.3 }))
  ]),
  slideIn: animation([
    style({ top: '150%' }),
    animate(durationMs + 'ms ease-out', style({ top: '50%' }))
  ]),
  slideOut: animation([
    style({ top: '50%' }),
    animate(durationMs + 'ms ease-in', style({ top: '150%' }))
  ]),
  slideInFromBottom: animation([
    style({ bottom: '-100%' }),
    animate(durationMs + 'ms ease-out', style({ bottom: 0 }))
  ]),
  slideOutToBottom: animation([
    style({ bottom: 0 }),
    animate(durationMs + 'ms ease-in', style({ bottom: '-100%' }))
  ]),
  durationMs
}
