import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations'

export class FadeInAnimation {
  public static animations = FadeInAnimation.getAnimations()

  public static getAnimations(): AnimationTriggerMetadata[] {
    return [
      trigger('fade', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate(350, style({ opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate(350, style({ opacity: 0 }))
        ])
      ]),
      trigger('bomb', [
       transition(':enter', [
        style({ height: 'auto', maxHeight: 0 }),
        animate('.35s ease-out', style({ height: 'auto', maxHeight: 600 }))
          ]),
      transition(':leave', [
       style({ height: 'auto', maxHeight: 600 }),
       animate('.35s ease-in', style({ height: 'auto', maxHeight: 0  }))])
          ]),
        trigger('dropDown', [
            state('in', style({
                overflowY: 'hidden',
                overflowX: 'visible',
                height: '*'
            })),
            state('out', style({
                overflowY: 'hidden',
                overflowX: 'visible',
                height: '0px'
            })),
            transition('in => out', animate('300ms ease-in-out')),
            transition('out => in', animate('300ms ease-in-out'))
        ])]
  }
}
