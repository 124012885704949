import { AfterContentInit, Component, ContentChildren, OnDestroy, QueryList } from '@angular/core'
import { ScrollService } from 'app/services/scroll.service'
import { Subscription } from 'rxjs'
import { PanelComponent } from '../panel/panel.component'

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html'
})
export class AccordionComponent implements AfterContentInit, OnDestroy {

  @ContentChildren(PanelComponent) private panels: QueryList<PanelComponent>
  private subscriptions = new Subscription()

  constructor(private scrollService: ScrollService) { }

  public ngAfterContentInit() {
    this.panels.forEach((panel: PanelComponent) => {
      const sub = panel.toggle.subscribe(e => {
        panel.opened = !panel.opened
        this.eventuallyScrollPanelIntoView(panel, true)
        // close all the other panels
        this.panels.forEach(p => { if (p !== panel) p.opened = false })
      })
      this.subscriptions.add(sub)
    })
    this.panels.forEach(p => this.eventuallyScrollPanelIntoView(p, false))
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  private eventuallyScrollPanelIntoView(panel: PanelComponent, smooth) {
    if (panel.opened) {
      const target = panel.panelHeading.nativeElement
      // would like to get rid of setTimeout to wait for browser render
      setTimeout(() => {
        this.scrollService.scrollTo(target, smooth)
      }, 0)
      return true
    }
    return false
  }
}
