import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { ErrorService } from 'app/utils/error-handling/error.service'

@Injectable({
  providedIn: 'root'
})
export class StartConsultationGuardService implements CanActivate {

  constructor(private router: Router,
              private localData: LocalDataService,
              private errorService: ErrorService,
              private communication: CommunicationService) {
  }

  public async canActivate(): Promise<boolean> {
    const { country, language } = this.localData.getLocale()
    const initialSettings = !!(country && language)
    if (!initialSettings) {
      this.router.navigate(['/'])
      return false
    } else {
      this.localData.setLocale({ country, language })
      try {
      const token = await this.communication.autoLogin()
      if (token) {
        return true
      } else {
        this.router.navigate(['/login'])
        return false
      }
      } catch (e) {
        await this.errorService.handleError(e)
        return false
      }
    }
  }
}
