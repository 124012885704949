import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FadeInAnimation } from 'app/utils/fade-in-animation'

@Component({
  selector: 'app-backdrop',
  templateUrl: './backdrop.component.html',
  styleUrls: ['./backdrop.component.scss'],
  animations: FadeInAnimation.animations
})
export class BackdropComponent {
@Output() public readonly backdropClicked = new EventEmitter<any>()
@Input() public lightTheme = false

public onClick() {
  this.backdropClicked.emit()
}
}
