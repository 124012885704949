export class PNTError extends Error {
  constructor(
      public readonly code: string = 'NO_CODE',
      message: string = 'no message') {
    super(message)
  }
}

export function throwErr({ code, message }: { code?: string, message?: string}) {
  throw new PNTError(code, message)
}

export class IllegalArgError extends PNTError {
  constructor(message: string) {
    super('IllegalArg', message)
  }
}

export type Nullable<T> = T | null

// https://schneidenbach.gitbooks.io/typescript-cookbook/nameof-operator.html
export const nameofFactory = <T>() => (name: keyof T) => name
