import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
  public transform(items: any[], searchText: string, properties: string[]): any[] {
    searchText = searchText.toLowerCase()
    if (!items) {
      return null
    }
    if (!searchText || !properties || properties.length === 0) {
      return items
    }
    return items.filter(item => {
      return properties.some(property => {
        const propertyValue = this.getPropertyValue(item, property);
        return propertyValue && propertyValue.toLowerCase().includes(searchText)
      })
    })
  }

  private getPropertyValue(item: any, property: string): string {
    const parts = property.split('.')
    let value = item;
    for (const part of parts) {
      if (value && value.hasOwnProperty(part)) {
        value = value[part];
      } else {
        return null
      }
    }
    return value
  }
}
