import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { LocalDataService } from 'app/services/local-data.service'
import { NavigationService } from 'app/services/navigation.service'
import { UtilitiesService } from 'app/services/utilities.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { Auth } from 'aws-amplify'
import { NavBackBarVariant } from '../uicomponents/back-navigation-bar/back-navigation-bar.component'

@Component({
  selector: 'app-sign-up-complete',
  templateUrl: './sign-up-complete.component.html',
  styleUrls: ['./sign-up-complete.component.scss'],
  animations: FadeInAnimation.animations
})
export class SignUpCompleteComponent {

  public navBackBarVariant = NavBackBarVariant
  constructor(private router: Router,
              private utilService: UtilitiesService,
              private navigation: NavigationService,
              private localData: LocalDataService,
              private translate: TranslateService
    ) { }

  public goToLogin() {
    this.utilService.clearWelcomeActive()
    Auth.signOut().then(() => {
      this.navigation.logout()
      this.localData.clearLocalConfirmPass()
      this.translate.setDefaultLang('empty')
      this.translate.use('en')
      this.router.navigate(['/login'])
    })
  }
}
