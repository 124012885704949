import { AbstractControl, ValidationErrors } from '@angular/forms'

export function checkBsnValidator(control: AbstractControl): ValidationErrors {
  return (!isValidBSN(control.value)) ? { checkbsn: true } : null

  function isValidBSN(bsn) {
    bsn = addLeadingZerosToBSN(bsn)

    if (getSumBSN(bsn) % 11) {
      return false
    } else {
      return true
    }
  }

  function addLeadingZerosToBSN(bsn) {
    return ('000000000' + bsn).slice(-9)
  }

  function getSumBSN(bsn) {
    const a = parseInt(bsn[0], 10) * 9
    const b = parseInt(bsn[1], 10) * 8
    const c = parseInt(bsn[2], 10) * 7
    const d = parseInt(bsn[3], 10) * 6
    const e = parseInt(bsn[4], 10) * 5
    const f = parseInt(bsn[5], 10) * 4
    const g = parseInt(bsn[6], 10) * 3
    const h = parseInt(bsn[7], 10) * 2
    const i = parseInt(bsn[8], 10) * -1

    const sum = a + b + c + d + e + f + g + h + i
    return sum
  }
}
