import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core'
import { LocalDataService } from 'app/services/local-data.service'
import { environment } from 'environments/environment'

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnChanges {
  @Input() public content: any
  public apiHost = environment.API_HOST
  public lang = this.localDataService.getLocale().language
  public shortLink = ""

  constructor(private localDataService: LocalDataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.shortLink = this.content.data.link ? new URL(this.content.data.link).hostname : "";
  }
}
