import { Component, EventEmitter, OnInit } from "@angular/core";
import { FadeInAnimation } from "app/utils/fade-in-animation";
import { LocalDataService } from "app/services/local-data.service";
import { NavBackBarVariant } from "../uicomponents/back-navigation-bar/back-navigation-bar.component";
import { CommunicationService } from "app/services/communication.service";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";

export interface UserDetailParameters {
  user?: any;
  organizations: Array<string>;
  complete: EventEmitter<any>
}

@Component({
  selector: 'app-mfa-input',
  templateUrl: './mfa-input.component.html',
  styleUrls: ['./mfa-input.component.scss'],
  animations: FadeInAnimation.animations
})
export class MfaInputComponent implements OnInit {
  public navBackBarVariant = NavBackBarVariant
  public showWasSendAgain = false

  mfaForm = this.formBuilder.group({
    code: '',
  });

  constructor(
    private communicate: CommunicationService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
  }

  async ngOnInit() {
    await this.communicate.getMfaCode();
  }

  public goToStartConsultation() {
    this.router.navigate(['/start-consultation'])
  }

  async resendCode() {
    this.showWasSendAgain = false;
    await this.communicate.getMfaCode();
    this.showWasSendAgain = true;
  }

  public async sendMfaCode() {
    const code = this.mfaForm.value.code;

    if (code) {
      await this.communicate.sendMfaCode(code);
      this.router.navigate(['/user-management']);
    }
  }
}
