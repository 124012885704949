import { Component, OnDestroy, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { AnalyticsService, Event } from 'app/services/analytics'
import { LocalDataService } from 'app/services/local-data.service'
import { NavigationService } from 'app/services/navigation.service'
import { UtilitiesService } from 'app/services/utilities.service'
import { logger } from 'app/utils/logger'
import * as _ from 'lodash'
import { Subscription } from 'rxjs'
import { NavBackBarVariant } from '../uicomponents/back-navigation-bar/back-navigation-bar.component'

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
  private static TAG = 'comp::Questionnaire'
  public navBackBarVariant = NavBackBarVariant
  public context: any
  public step: any
  public showSideNav = false
  public currentLanguage: string

  private ctxSub: Subscription
  private stpSub: Subscription

  constructor(
    private navigate: NavigationService,
    private localDataService: LocalDataService,
    private translate: TranslateService,
    private utils: UtilitiesService,
    private analyticsService: AnalyticsService,
    private utilService: UtilitiesService
  ) {
    this.utilService.clearAssessmentNote()
    // tslint:disable-next-line:only-arrow-functions
    window.onbeforeunload = function(e) {
      navigate.step.subscribe(step => {
        if (!!step && !!step.header && _.get(step, 'header.type') !== 'assessment') {
          analyticsService.event(Event.RefreshAttempt, { ref_id1: step.header.id })
          // TODO find a way to display proper message
          return e.returnValue = 'Are you sure you want to leave?\nYour changes will not be saved'
        }
      })
    }
  }

  public ngOnInit() {
    this.utilService.setQuestionChangedDialog(false)
    this.stpSub = this.navigate.step.subscribe(step => {
      logger.info('%s - setting Step %o', QuestionnaireComponent.TAG, step)
      this.step = step
      if (step !== null && !!step.header) {
        this.analyticsService.event(Event.StepView, { ref_id1: step.header.id })
      }
    })

    this.ctxSub = this.navigate.context.subscribe(context => {
      this.context = context
    })
  }

  public ngOnDestroy() {
    this.stpSub.unsubscribe()
    this.ctxSub.unsubscribe()
    this.navigate.localOutput = { }
  }

  public showSideMenu() {
    this.showSideNav = true
  }

  public hideSideNav() {
    this.showSideNav = false
  }

}
