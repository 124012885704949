import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { LocalDataService } from '../local-data.service'

@Injectable({
    providedIn: 'root'
})
export class GeoBlockGuardService implements CanActivate {

    constructor(private router: Router,
                private localData: LocalDataService) {
    }

    public async canActivate(): Promise<boolean> {
        this.localData.clear()
        this.localData.setCountryOverride(true)
        await this.router.navigate(['/'])
        return true
    }
}
