import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'
import { AnalyticsService, Event } from '../../../services/analytics'

export enum AnnotationsVariant {
  Default,
  ReferralIdentifier,
  Secondary,
  NoBackground
}
@Component({
  selector: 'app-annotations',
  templateUrl: './annotations.component.html',
  styleUrls: ['./annotations.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnnotationsComponent {
  @Input() public content: any
  @Input() public variant: AnnotationsVariant = AnnotationsVariant.Default
  @Output() public readonly linkTo: EventEmitter<any> = new EventEmitter()
  public annotationsVariant = AnnotationsVariant

  constructor(private analyticsService: AnalyticsService) {
  }

  public onClick(action) {
    this.linkTo.emit(action)
  }

  public onBodyClick(e) {
    if ((e.target.nodeName.toUpperCase() === 'A')) {
      this.analyticsService.event(Event.ExtLinkOpen, { value1: e.target.href })
    }
  }
}
