import { Component, OnInit } from '@angular/core'
import { environment } from 'environments/environment'
import { CookieService } from 'ngx-cookie-service'

@Component({
  selector: 'app-tag-manager',
  template: ''
})
export class TagManagerComponent implements OnInit {
  private uaid: string = environment.analytics.uaid

  constructor(private cookieService: CookieService) { }

  public ngOnInit() {
    let flag = true

    setInterval(() => {
      const checkCookie = this.cookieService.check('wscrCookieConsent')

      if (checkCookie) {
        const cookie = this.cookieService.get('wscrCookieConsent')
        const tag = this.parseQuery(cookie)

        if (tag[3] === 'true' && flag) {
          this.createTag(this.uaid)
          flag = false
        } else if (tag[3] === 'false') {
          if (document.getElementById('tagmanager')) {
            document.getElementById('tagmanager').remove()
          }
          flag = true
        }
      }
    }, 100)
  }

  private parseQuery(queryString) {
    const query = {}
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
    for (const p of pairs) {
        const pair = p.split('=')
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
    }
    return query
  }

  private createTag(uaid) {
    const script = ​document​.createElement(​'script'​)
    const head = ​document​.getElementsByTagName(​'head'​)

    if (document.getElementById('tagmanager')) {
      document.getElementById('tagmanager').remove()
    }

    script.id = 'tagmanager'
    script.async = true
    script.src = ​'https://www.googletagmanager.com/gtag/js?id=' + uaid
    head[​0​].appendChild(script)
  }
}
