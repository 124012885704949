import { Component, Input } from '@angular/core'
import { NavigationService } from 'app/services/navigation.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { AnnotationsVariant } from '../../../uicomponents/annotations/annotations.component'
@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  animations: FadeInAnimation.animations
})
export class TextComponent {
  @Input() public data: PnPage
  @Input() public context: any
  public annotationsVariant = AnnotationsVariant
  private output = { }

  constructor(private navigate: NavigationService) {
  }

  public navigateChange = (to: any): void => {
    const data = {
      answer: {
        toStepId: this.data.header.id,
        inputs: this.output
      },
      context: null
    }
    if (to.direction === 'next') this.navigate.nextPage(data)
    else {
      this.navigate.jumpTo(to, data)
    }
  }
}
