import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'localeLogo'
})
export class LocaleLogoPipe implements PipeTransform {
  public transform(value: any, brand: string ): string {
    if (brand) {
      return './assets/logos/' + brand + '/' + value
    } else {
      return './assets/logos/referid/' + value
    }
  }

}
