import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { LocalDataService } from 'app/services/local-data.service'
import { NavigationService } from 'app/services/navigation.service'
import { ScrollService } from 'app/services/scroll.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { NavBackBarVariant } from '../uicomponents/back-navigation-bar/back-navigation-bar.component'

@Component({
  selector: 'app-multi-step-form',
  templateUrl: './multi-step-form.component.html',
  styleUrls: ['./multi-step-form.component.scss'],
  animations: FadeInAnimation.animations
})
export class MultiStepFormComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public formContent: any
  @Output() public readonly formSubmit: EventEmitter<any> = new EventEmitter<any>()
  @Output() public readonly updateFormIndex: EventEmitter<any> = new EventEmitter<any>()
  @Input() public activeStepIndex
  @ViewChild('multiStepContentRef', { read: ElementRef, static: false }) private multiStepContentRef: ElementRef
  public navBackBarVariant = NavBackBarVariant
  public currentFormContent: any[] // acitve step form data
  public formData: any // holds all forms data
  public formFields: string[][] // holds values of all fields of all forms
  public masterFormFields: string[] // holds all form groups
  public stepItems: any [] // holds array of multistep forms
  public masterForm: FormGroup[] // holds various steps forms
  public backRouteName?: string = '/login'
  public firstName: string

  constructor(
    private readonly _formBuilder: FormBuilder,
    private router: Router,
    private navigate: NavigationService,
    private localData: LocalDataService,
    private translate: TranslateService,
    private scrollService: ScrollService
  ) {

  }

  public ngOnInit() {
    this.masterForm = []
    this.currentFormContent = []
    this.formFields = []
    const { firstName, role } = this.localData.getUserDetail()
    this.stepItems = this.formContent.filter(step => !(role && step.data.fields && step.data.fields.role))
    this.firstName = firstName
    for (const step of this.stepItems ) {
      if (step.data && step.data.fields) {
        this.formFields.push(Object.keys(step.data.fields))
        this.currentFormContent.push(step.data.fields)
        this.masterForm.push(this.buildForm(step.data.fields))
      }
    }
  }

  public translateOptions(field) {
    return field.options.map(option => {
      if (field.id === 'role') {
        option.label = this.translate.instant(`signUp.steps_1.role.${option.id}`)
      } else if (field.id === 'lungFunction') {
        option.label = this.translate.instant(`multiForm.${field.id}.${option.id}.label`)
        option.description = this.translate.instant(`multiForm.${field.id}.${option.id}.description`)
      } else {
        option.description = this.translate.instant(`multiForm.${field.id}.${option.id}.description`)
      }
      return option
    })
  }

  // @buildForm creating separate FormGroups for each form
  public buildForm(currentFormContent: any): FormGroup {
    const formDetails = Object.keys(currentFormContent).reduce(
      (obj, key) => {
        obj[key] = this._formBuilder.control('', this.getValidators(currentFormContent[key]))

        return obj
      },
      { }
    )

    return this._formBuilder.group(formDetails)
  }

  // get validator(s) for each field, if any
  public getValidators(formField: any): Validators {
    const fieldValidators = Object.keys(formField.validations).map(validator => {
      if (validator === 'required') {
        return Validators[validator]
      } else {
        return Validators[validator](formField.validations[validator])
      }
    })

    return fieldValidators
  }

  public goToStartConsultation() {
    this.router.navigate(['/start-consultation'])
  }

  public goToStep(step: string): void {
    if (this.activeStepIndex === 0 && step === 'prev') {
      this.navigate.logout()
      this.router.navigate([this.backRouteName])
    } else {
      if (this.activeStepIndex === this.stepItems.length - 2 && step === 'next') {
        this.formData = this.masterForm.reduce(
          (masterForm, currentForm) => ({ ...masterForm, ...currentForm.value }),
          { }
        )

        this.masterFormFields = Object.keys(this.formData)
        const { role } = this.localData.getUserDetail()
        this.formSubmit.emit({ role, ...this.formData })
      } else {
        this.updateFormIndex.emit((step === 'prev') ? this.activeStepIndex - 1 : this.activeStepIndex + 1)
      }
    }
  }

  public ngOnChanges(data) {
    const { activeStepIndex } = data
    if (activeStepIndex.previousValue !== undefined && activeStepIndex.previousValue !== activeStepIndex.currentValue) {
      const target = this.multiStepContentRef.nativeElement
      this.scrollService.scrollTo(target, false)
    }
  }

  public ngAfterViewInit() {
    const target = this.multiStepContentRef.nativeElement
    this.scrollService.scrollTo(target, false)
  }
}
