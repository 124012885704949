import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { AnalyticsService, Event } from 'app/services/analytics'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { ErrorService } from 'app/utils/error-handling/error.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { DialogConfig, DialogVariant } from '../dialog/dialog-config'
import { DialogRef } from '../dialog/dialog-ref'
import { DialogService } from '../dialog/dialog.service'
import { FirstTimeTourComponent } from '../first-time-tour/first-time-tour.component'
import { NavBackBarVariant } from '../uicomponents/back-navigation-bar/back-navigation-bar.component'
import SettingsData from './user-settings.data'

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  animations: FadeInAnimation.animations
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  public navBackBarVariant = NavBackBarVariant
  public basicInfoForm: FormGroup
  public basicInfoFormData: any
  public settingsData: any = SettingsData
  private dataLossDialog: DialogRef = null
  private originalData: any
  private unsubscribe$ = new Subject()
  public user: any

  constructor(private _formBuilder: FormBuilder,
              private errorService: ErrorService,
              private communication: CommunicationService,
              private translate: TranslateService,
              private router: Router,
              private localData: LocalDataService,
              private analytics: AnalyticsService,
              private dialog: DialogService) {
    const { basicInfo } = this.settingsData
    this.basicInfoFormData = basicInfo
    this.basicInfoForm = this.buildForm(this.basicInfoFormData.sections)
  }

  public ngOnInit() {
    this.getLanguages()
    this.getSettings()
    this.analytics.event(Event.SettingsEdit)
  }

  public takeTour(e) {
    e.preventDefault()
    if ((e.target.nodeName.toUpperCase() === 'A')) {
      const config = new DialogConfig<any>()
      config.withButtons = false
      config.fixedHeight = true
      config.variant = DialogVariant.FirstTimeTour
      this.dialog.open(FirstTimeTourComponent, config)
    }
  }

  // build separate FormGroups for each form
  public buildForm(formSections: any): FormGroup {
    const formGroups = { }
    formSections.forEach(section => {
      section.fields.forEach(element => {
        if (element.type !== 'cta' && element.type !== 'plain-text') {
          formGroups[element.name] = this._formBuilder.control(element.value, this.getValidators(element))
        }
      })
    })
    return this._formBuilder.group(formGroups)
  }

  // get validator(s) for each field, if any
  public getValidators(formField: any): Validators {
    const fieldValidators = Object.keys(formField.validations).map(validator => {
      if (validator === 'required') {
        return Validators[validator]
      } else {
        return Validators[validator](formField.validations[validator])
      }
    })
    return fieldValidators
  }

  public throwAlert() {
    const config = new DialogConfig<any>()
    config.backDrop = true
    config.title = this.translate.instant('userSettings.exitDialog.title')
    config.subTitle = this.translate.instant('userSettings.exitDialog.body')
    config.doneLabel = this.translate.instant('userSettings.exitDialog.confirm')
    config.cancelLabel = this.translate.instant('userSettings.exitDialog.cancel')
    this.dataLossDialog = this.dialog.open(null, config)
    this.dataLossDialog.done
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(e => {
        this.dataLossDialog.close()
        this.onSubmitBasicInfo()
      })
    this.dataLossDialog.cancel
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(e => {
        this.dataLossDialog.close()
        this.goBack()
      })
  }

  private async getSettings() {
    try {
      const data = await this.communication.getUserSettings()
      const { guideline, lungFunction, language, role, user } = data
      let userLanguage = this.localData.getLocale().language || language;
      let userCountryLabel = user.country
      const { config } = await this.communication.getCountryConfig()
      const userCountry = config.find(item => item.code.toLocaleLowerCase() === user.countryCode.toLocaleLowerCase())
      const foundUserLanguage = userCountry.languages.find(item => item.code === language)
      if (!foundUserLanguage) {
        userLanguage = userCountry.defaultLang
        userCountryLabel = userCountry.label
      }
      this.originalData = {
        guideline,
        language: userLanguage,
        lungFunction,
        role
      }
      this.basicInfoForm.patchValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        country: userCountryLabel,
        role,
        language: userLanguage,
        guideline,
        lungFunction
      })
      this.user = user

    } catch (error) {
      this.errorService.handleError(error)
    }
  }

  private async putSettings(putData) {
    const newUser = ({
      firstname: this.user.firstName, name: this.user.lastName,
      email: this.user.email,
      organization: this.user.organization,
      lufu: putData.lungFunction,
      role: putData.role,
      language: putData.language,
      asq: putData.guideline
    });

    await this.communication.updateUser(newUser);

    this.analytics.event(Event.SettingsEdit, {
      value1: putData.guideline,
      user_language: putData.language,
      value2: putData.lungFunction
    });
    this.basicInfoForm.markAsPristine();
    this.localData.setLocale(putData);
    this.router.navigate(['/start-consultation']);
  }

  public async getLanguages() {
    try {
      const { detected, config } = await this.communication.getCountryConfig()
      const detectedCountry = config.find(e => e.code === detected.country)
      this.basicInfoFormData.sections[0].fields.map(field => {
        if (field.id === 'language') {
          field.options = detectedCountry.languages
        }
      })
    } catch (error) {
      this.errorService.handleError(error)
    }
  }

  public goToStartConsultation() {
    this.router.navigate(['/start-consultation'])
  }

  public onSubmitBasicInfo() {
    const { lungFunction, guideline, language, role } = this.basicInfoForm.value
    const putData = {
      lungFunction,
      guideline,
      language,
      role
    }
    this.analytics.event(Event.SettingsSaved, {
      value1: guideline,
      user_language: language,
      value2: lungFunction
    })
    this.putSettings(putData)
  }

  public async onLanguageSelected() {
    const countryCode = this.localData.getLocale().country
    const localeTemp = {
      country: countryCode,
      language: this.basicInfoForm.controls.language.value
    }
    this.localData.setLocale(localeTemp)
    const { config } = await this.communication.getCountryConfig()
    const userCountry = config.find(item => item.code.toLocaleLowerCase() === countryCode.toLocaleLowerCase())
    this.basicInfoFormData.sections[0].fields.map(field => {
      if (field.id === 'language') {
        field.options = userCountry.languages
      }
    })
    this.basicInfoForm.patchValue({
      country: userCountry.label
    })
  }

  public goBack() {
    const localeTemp = { country: this.localData.getLocale().country , language: this.originalData.language }
    this.localData.setLocale(localeTemp)
    this.putSettings(this.originalData)
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
