
export enum DialogVariant {
  Default,
  FirstTimeTour
}
export class DialogConfig<D = any> {
  public backDrop: boolean = false
  public title: string
  public subTitle?: string
  public content?: string
  public doneLabel?: string
  public cancelLabel?: string
  public custom?: D
  public noPrimary: boolean = false
  public withButtons: boolean = true
  public variant: DialogVariant = DialogVariant.Default
  public errorStyle: boolean = false
  public fixedHeight: boolean = false
}
