import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-question-radio-buttons',
  templateUrl: './question-radio-buttons.component.html',
  styleUrls: ['./question-radio-buttons.component.scss']
})
export class QuestionRadioButtonsComponent {
  @Input() public field: any
  @Input() public forceOneColumn: boolean
  @Input() public index: number
  @Input() public parentFormGroup: FormGroup
  @Input() public control: any
  @Output() public readonly onClicked = new EventEmitter<any>()
  @Output() public readonly questionChangedAlert = new EventEmitter<any>()
  @Output() public readonly otherTextChangedAlert = new EventEmitter<any>()
  @Output() public readonly skipStep = new EventEmitter<any>()
  @Output() public readonly infoToggled = new EventEmitter<any>()
}
