import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { DialogConfig } from 'app/components/dialog/dialog-config'
import { DialogService } from 'app/components/dialog/dialog.service'
import { ashtmaNavigator, referralIdentifier, Tool } from 'app/interfaces/theme.interface'
import { AnalyticsService, Event, Prop } from 'app/services/analytics'
import { CommunicationService } from 'app/services/communication.service'
import { LoaderService } from 'app/services/loader.service'
import { LocalDataService } from 'app/services/local-data.service'
import { NavigationService } from 'app/services/navigation.service'
import { ThemeService } from 'app/services/theme.service'
import { ErrorService } from 'app/utils/error-handling/error.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { logger } from 'app/utils/logger'
import { environment } from 'environments/environment'
import { Subscription } from 'rxjs'
import { NavBackBarVariant } from '../uicomponents/back-navigation-bar/back-navigation-bar.component'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: FadeInAnimation.animations
})
export class LoginComponent implements OnInit, OnDestroy {
  private static readonly TAG = 'comp::Login'
  public navBackBarVariant = NavBackBarVariant
  public loginForm: FormGroup
  public expandedText = false
  public expandedHelpBanner = false
  public formSubmitAttempt = false
  public forgetPasswordAttempt = false
  public errorMessage: {
    message: string
  } = null
  public inputPasswordType = 'password'
  private subscription = new Subscription()

  public language: string = 'en'

  public activeTool: string
  public referralIdentifier: string = referralIdentifier.name
  public asthmaNavigator: string = ashtmaNavigator.name
  public brand: string = ''
  public brandName: string = ''
  public emailInputFocus = false
  public passwordInputFocus = false
  public notificationHidden = true
  public notification: string = ''

  constructor(
    private fb: FormBuilder,
    private navigation: NavigationService,
    private communication: CommunicationService,
    private errorService: ErrorService,
    private localData: LocalDataService,
    private loader: LoaderService,
    private titleService: Title,
    private dialog: DialogService,
    private translate: TranslateService,
    private themeService: ThemeService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  public async ngOnInit() {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    this.route.queryParams
      .subscribe(params => {
          if (typeof params.notification !== 'undefined') {
            this.notification = atob(params.notification)
            if (this.notification !== '') {
              this.notificationHidden = false
            }
          }
        }
      )

    this.loginForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern(emailRegex)
      ]],
      // no need to validate password at login
      password: ['', [
        Validators.required,
        Validators.minLength(1)
      ]]
    })

    const { country, language } = this.localData.getLocale()
    this.activeTool = this.localData.getLastActiveTool()
    // needed to clear navigation in case of back/forward from browsers
    this.navigation.logout()

    this.analyticsService.setProp(Prop.Country, country)
    this.analyticsService.setProp(Prop.Lang, language)
    this.analyticsService.setProp(Prop.Tool, this.activeTool)
    try {
      const { detected, config } = await this.communication.getCountryConfig()
      const detectedCountry = config.find(e => e.code === detected.country)
      // check if the current tool and language are still available for this country
      if (!detectedCountry
              || !detectedCountry.variants.includes(this.activeTool)
              || !detectedCountry.languages.find(e => e.code === language)) {
        this.localData.clear()
        await this.router.navigate(['/'])
      }
      // check if current country has a name variation of the tool
      if (detectedCountry && detectedCountry.branding) {
        this.brand = detectedCountry.branding.logo
        this.brandName = detectedCountry.branding.label + (this.activeTool === this.asthmaNavigator ? '+' : '')
        this.titleService.setTitle(detectedCountry.branding.label + (this.activeTool === this.asthmaNavigator ? '+' : ''))
      } else {
        const toolName = 'Asthma Optimiser' + (this.activeTool === this.asthmaNavigator ? '' : '')
        this.titleService.setTitle(toolName)
        this.brandName = toolName
      }
      this.themeService.updateTheme(this.activeTool)
    } catch (e) {
      await this.errorService.handleError(e)
    }

  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  public async forgotPassword() {
    if (this.loginForm.get('email').invalid) {
      this.forgetPasswordAttempt = true
      this.loginForm.get('email').updateValueAndValidity()
      return
    }
    this.analyticsService.event(Event.ForgetPasswordStart )
    const email = this.loginForm.value.email.toLowerCase()
    this.loader.show()
    this.communication.forgotPassword(email).then(() => {
      const config = new DialogConfig();
      config.backDrop = true
      config.title = this.translate.instant('login.reset.success.title');
      config.subTitle = this.translate.instant('login.reset.success.subtitle');
      config.withButtons = false
      this.dialog.open(null, config)
    }).catch(e => {
      console.log(e);
      const config = new DialogConfig();
      config.title = this.translate.instant('login.reset.error.title');
      config.subTitle = this.translate.instant('login.reset.error.subtitle');
      config.withButtons = true;
      this.dialog.openError(null, config);
    })
  }

  public async onSubmit() {
    if (!this.loginForm.valid) {
      this.formSubmitAttempt = true
      return
    }
    try {
      this.loader.show()
      await this.navigation.login(this.loginForm.value.email, this.loginForm.value.password)
      logger.info('%s - Login call done', LoginComponent.TAG)
      this.analyticsService.event(Event.LoginSuccess)
    } catch (e) {
      logger.error('%s - Login Error', LoginComponent.TAG, e)
      if (e.code === 'NotAuthorizedException') {
        this.formSubmitAttempt = true
        this.errorMessage = { message: this.translate.instant('login.credentialsError') }
        this.analyticsService.event(Event.LoginFail, { value1: 'credentialsError' })
      } else if (e.code === 'NeedComplete') {
        this.errorMessage = { message: this.translate.instant('login.needCompleteError') }
        this.analyticsService.event(Event.LoginFail, { value1: 'needCompleteError' })
      } else {
        if (environment.production === true) {
          e.customMessage = this.translate.instant('login.networkError')
        }
        this.errorService.handleError(e)
        this.analyticsService.event(Event.LoginFail, { value1: 'networkError' })
      }
    } finally {
      this.loader.hide()
    }
  }

  public async guestLogin() {
    logger.info('Calling guestLogin()')

    try {
      const { detected, config } = await this.communication.getCountryConfig()
      const detectedCountry = config.find(e => e.code === detected.country)
      const data = {
        context: {
          team: {
            veeva: this.translate.instant('localID_referralidentifier'),
            referralLetterBtn: detectedCountry.referralLetter
          }
        }
      }

      await this.navigation.startConsultation(data)
      this.analyticsService.event(Event.Start)
    } catch (e) {
      logger.error('%s - guestLogin() startConsultation %o', LoginComponent.TAG, e)
      this.errorService.handleError(e)
    }
  }

  public openDetailsDialog(e) {
    e.preventDefault()
    if (e.target.nodeName.toUpperCase() === 'A' && e.target.pathname === '/experts') {
      const config = new DialogConfig()
      config.backDrop = true
      if (this.activeTool === this.asthmaNavigator) {
        config.title = this.translate.instant('login.an.experts.modal.title')
        config.subTitle = this.translate.instant('login.an.experts.modal.subtitle')
        config.content = this.translate.instant('login.an.experts.modal.content')
      } else {
      config.title = this.translate.instant('login.experts.modal.title')
      config.subTitle = this.translate.instant('login.experts.modal.subtitle')
      config.content = this.translate.instant('login.experts.modal.content')
      }
      config.withButtons = false
      this.dialog.open(null, config)
    } else if (e.target.nodeName.toUpperCase() === 'A' && e.target.pathname === '/GINA') {
      this.openGuidelinesDialog(e)
    } else if (e.target.nodeName.toUpperCase() === 'A' && e.target.pathname === '/referid') {
      this.navigation.resetContext()
      this.localData.setLastActiveTool(referralIdentifier.name)
      this.router.routeReuseStrategy.shouldReuseRoute = () => false
      this.router.navigateByUrl('/login?').then(() => {
        this.router.navigated = false
        this.router.navigate(['/login'])
      })
    } else if (e.target.nodeName.toUpperCase() === 'A') {
      window.open(e.target.href, '_blank')
    }
  }

  public openGuidelinesDialog(e) {
    e.preventDefault()
    if ((e.target.nodeName.toUpperCase() === 'A')) {
      const config = new DialogConfig()
      config.backDrop = true
      config.title = this.translate.instant('login.guidelines.modal.title')
      const key = this.activeTool === Tool.ashtmanavigator ? 'login.guidelines.modal.subtitle' : 'login.guidelines.ri.modal.subtitle'
      const contentKey = this.activeTool === Tool.ashtmanavigator ? 'login.guidelines.modal.content' : 'login.guidelines.ri.modal.content'
      config.subTitle = this.translate.instant(key)
      config.content = this.translate.instant(contentKey)
      config.withButtons = false
      this.dialog.open(null, config)
    }
  }

  public signUp() {
    this.router.navigate(['sign-up'])
  }

  public onExpandText() {
    this.expandedText = true
  }
}
