import { animate, animateChild, query, style, transition, trigger } from '@angular/animations'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { LocalDataService } from 'app/services/local-data.service'
import { PwaService } from 'app/services/pwa-service.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeOut', [
      transition(':leave', [
        query(':leave', animateChild(), { optional: true }),
        animate(300, style({ opacity: 0 }))
      ])
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        query(':enter', animateChild(), { optional: true }),
        animate(300, style({ opacity: 1 }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplashScreenComponent implements OnInit, OnDestroy {
 private unsubscribe$ = new Subject()
 public show = true
 public countrySelected = false

  constructor(
    private pwaService: PwaService,
    private cdr: ChangeDetectorRef,
    private localData: LocalDataService
  ) {
  }

  public async ngOnInit() {
    this.countrySelected = !!this.localData.getLocale().country
    this.pwaService.checkForUpdate()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        this.show = result
        this.cdr.detectChanges()
      })
  }

    public ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

}
