import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { ashtmaNavigator, referralIdentifier } from 'app/interfaces/theme.interface'
import set = Reflect.set
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from '../../services/local-data.service'
import { ThemeService } from '../../services/theme.service'
import { FadeInAnimation } from '../../utils/fade-in-animation'
import { NavBackBarVariant } from '../uicomponents/back-navigation-bar/back-navigation-bar.component'

@Component({
  selector: 'app-select-tool',
  templateUrl: './select-tool.component.html',
  styleUrls: ['./select-tool.component.scss'],
  animations: FadeInAnimation.animations
})
export class SelectToolComponent implements OnInit {
  public navBackBarVariant = NavBackBarVariant
  public selectToolForm: FormGroup
  public formSubmitAttempt = false
  public activeTool: string
  public referralIdentifier: string = referralIdentifier.name
  public asthmaNavigator: string = ashtmaNavigator.name
  public brand: string = ''
  public brandName: string = ''
  public localVeevaId: string = 'localID_referralidentifier'

  constructor(private fb: FormBuilder,
              private router: Router,
              private localData: LocalDataService,
              private themeService: ThemeService,
              private communication: CommunicationService) { }

  public ngOnInit() {
    const lastActiveTool = this.localData.getLastActiveTool()
    this.selectToolForm = this.fb.group({
      tool: [!!lastActiveTool ? lastActiveTool : '', [
        Validators.required
      ]]
    })
    if (!!lastActiveTool) {
      this.activeTool = lastActiveTool
      setTimeout(() => this.themeService.updateTheme(this.activeTool))
    }
    this.updateBrand()
    this.selectToolForm.controls.tool.valueChanges.subscribe( value => {
      this.activeTool = value
      this.themeService.updateTheme(this.activeTool)
    })
  }

  private async updateBrand() {
    const { detected, config } = await this.communication.getCountryConfig()
    const detectedCountry = config.find(e => e.code === detected.country)
    if (detectedCountry && detectedCountry.branding) {
      this.brand = detectedCountry.branding.logo
      this.brandName = detectedCountry.branding.label
    } else {
      this.brandName = 'Asthma Optimiser'
    }
  }

  public onSubmit() {
    if (this.selectToolForm.valid) {
      this.localData.setLastActiveTool(this.activeTool)
      this.router.navigate(['/login'])
    } else {
      this.formSubmitAttempt = true
    }
  }

}
