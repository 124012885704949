// Will be modified at runtime in main.ts, this is just
// to please type checking
export const environment = {
  language: 'en',
  locale: 'en',
  API_HOST: 'http://localhost:3000',
  ENDPOINT: '/api/v1',
  production: false,
  version: 'n.d.',
  logLevel: 'trace',
  buildno: 0,
  amplify: {},
  analytics: {
    enabled: true,
    uaid: 'to_be_replaced'
  },
  reCaptcha: {
    siteKey: 'xyz-xyz'
  }
}
