import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['../input-number/input-number.component.scss']
})
export class InputSelectComponent {
  @Input() public parentFormGroup
  @Input() public control
  @Input() public formSubmitAttempt
  @Input() public field

  public getTemplateLabel(pos, label) {
    const parts: string[] = label.split('$0')
    const pre = parts[0] ? (parts[0]) : ''
    const post = parts[1] ? (parts[1]) : ''
    return pos === 'pre' ? pre : post
  }

}
