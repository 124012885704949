import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { LoaderService } from 'app/services/loader.service'
import { LocalDataService } from 'app/services/local-data.service'
import { ErrorService } from 'app/utils/error-handling/error.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { Auth } from 'aws-amplify'
import { Event } from '../../services/analytics'
import { NavBackBarVariant } from '../uicomponents/back-navigation-bar/back-navigation-bar.component'

@Component({
  selector: 'app-confirm-sign-up',
  templateUrl: './confirm-sign-up.component.html',
  styleUrls: ['./confirm-sign-up.component.scss'],
  animations: FadeInAnimation.animations
})
export class ConfirmSignUpComponent implements OnInit {
  public navBackBarVariant = NavBackBarVariant
  public email: string = ''
  public lang: string = 'en'
  public inputCodeType = 'password'
  public inputPasswordType = 'password'
  public inputConfirmPasswordType = 'password'
  public confirmForm: FormGroup
  public formSubmitAttempt = false
  public emailInputFocus = false
  public codeInputFocus = false
  public passwordInputFocus = false
  public confirmPasswordInputFocus = false
  constructor(
    private router: Router,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private translate: TranslateService,
    private localData: LocalDataService) {
    this.route.queryParams.subscribe(params => {
      this.email = params.user
      this.lang = params.lang
    })
  }

  public ngOnInit() {
    this.localData.setLocale({ language: this.lang })
    this.confirmForm = new FormGroup({
      email: new FormControl(this.email, Validators.email),
      code: new FormControl('', [ Validators.required, Validators.pattern('.{8,}') ]),
      password: new FormControl('', [ Validators.required,
         Validators.pattern('(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\\W_]).{8,}')]),
      confirmPassword: new FormControl('', [Validators.required])
    },
    { validators: this.validatePassword })
  }

  private validatePassword(control: AbstractControl) {
    const password = control.get('password').value
    const confirmPassword = control.get('confirmPassword').value
    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({
        matchPassword: true
      })
    } else {
      return null
    }
  }

  public confirmCode() {
    if (this.confirmForm.valid) {
      const { email, code, password } = this.confirmForm.value
      this.loader.show()
      Auth.signIn(email, code.trim())
        .then( data => {
          Auth.completeNewPassword(data, password, []).then(() => {
            this.loader.hide()
            this.goToSigUpComplete()
          }).catch((error: any) => {
            this.loader.hide()
            this.errorService.handleError(error)
          })
        }).catch((error: any) => {
        this.loader.hide()
        if (error.code === 'NotAuthorizedException') {
          error.customMessage = this.translate.instant('signUpConfirm.credentialsError')
        }
        this.errorService.handleError(error)
      })
    } else {
      this.formSubmitAttempt = true
    }
  }

  public goToSigUpComplete() {
    this.router.navigate(['/signup-complete'])
  }

  get confirmPassword() {
    return this.confirmForm.get('confirmPassword')
  }
  public fieldBlur() {
    this.confirmPassword.updateValueAndValidity()
  }
}
