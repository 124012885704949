import { Component, Input } from '@angular/core'
import { PageInterface } from 'app/interfaces/page.interface'
/* Utility Service */
import { NavigationService } from 'app/services/navigation.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  animations: FadeInAnimation.animations
})

export class ChapterComponent implements PageInterface {
  @Input() public data: PnPage
  @Input() public context: any // Should be PnContext

  public loading: boolean = false

  constructor(
    private navigate: NavigationService
  ) {
  }

  public navigateChange = (to: any): void => {
    this.loading = true
    const data = {
      answer: {
        toStepId: this.data.header.id
      },
      context: null
    }
    if (to === 'next') this.navigate.nextPage(data)
  }
}
