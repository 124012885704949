import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { DialogRef } from 'app/components/dialog/dialog-ref'
import { AnalyticsService, Event } from 'app/services/analytics'
import { LocalDataService } from 'app/services/local-data.service'

@Component({
  selector: 'app-first-time-tour',
  templateUrl: './first-time-tour.component.html',
  styleUrls: ['./first-time-tour.component.scss']
})
export class FirstTimeTourComponent implements OnInit {
  public stepItems = [{
    title: 'ftu.step1.title',
    subTitle: 'ftu.step1.subTitle',
    img: 'ftu-welcome'
  },
  {
    title: 'ftu.step2.title',
    content: 'ftu.step2.content',
    img: 'ftu-scrolling'
  },
  {
    title: 'ftu.step3.title',
    content: 'ftu.step3.content',
    img: 'ftu-navigation'
  },
  {
    title: 'ftu.step4.title',
    content: 'ftu.step4.content',
    img: 'ftu-management-plan'
  },
  {
    title: 'ftu.step5.title',
    content: 'ftu.step5.content',
    img: 'ftu-end-assessment'
  }]

  public activeIndex: number = 0

  constructor(
    private dialogRef: DialogRef,
    private localData: LocalDataService,
    private translate: TranslateService,
    private analytics: AnalyticsService) { }

  public ngOnInit(): void {
    this.stepItems.forEach(e => {
      e.title = this.translate.instant(e.title) || e.title
      e.subTitle = e.subTitle ? this.translate.instant(e.subTitle) : null
      e.content = e.content ? this.translate.instant(e.content) : null
    })
  }

  public closeTour() {
    this.analytics.event(Event.FtuSkip)
    this.markAndClose()
  }

  private markAndClose() {
    this.localData.setFirstTimeTour()
    this.dialogRef.close()
  }

  public goToStep(type) {
    if (type === 'next' && this.activeIndex < this.stepItems.length - 1) {
      if (this.activeIndex === 0) this.analytics.event(Event.FtuYes)
      this.activeIndex++
    } else if (type === 'prev' && this.activeIndex > 0) {
      this.activeIndex--
    } else if (this.activeIndex === this.stepItems.length - 1) {
      this.markAndClose()
    }
  }
}
