import { Component, EventEmitter } from "@angular/core";
import { FadeInAnimation } from "app/utils/fade-in-animation";
import { DialogRef } from "../dialog/dialog-ref";
import { CommunicationService } from "app/services/communication.service";
import { DialogConfig } from "../dialog/dialog-config";
import { TranslateService } from "@ngx-translate/core";

export interface UserDetailParameters {
  user?: any;
  organizations: Array<string>;
  complete: EventEmitter<any>
}

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  animations: FadeInAnimation.animations
})
export class UserDetailsComponent {
  public email: string = "";
  public firstname: string = "";
  public name: string = "";
  public organization: string = "";
  public isEdit: boolean = false;
  public organizations = ["Foobar"];

  constructor(
    public dialog: DialogRef,
    public config: DialogConfig<UserDetailParameters>,
    private communication: CommunicationService,
    private translate: TranslateService,
  ) {
    this.dialog.done.subscribe(async () => {
      const user = { email: this.email, firstname: this.firstname, name: this.name, organization: this.organization };

      if (!user.email) {
        alert(this.translate.instant('userManagement.user.missingEmail'));
        return;
      }

      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(user.email)) {
        alert(this.translate.instant('userManagement.user.invalidEmail'));
        return;
      }

      if (!user.firstname || user.firstname.length < 2) {
        alert(this.translate.instant('userManagement.user.missingFirstName'));
        return;
      }

      if (!user.name || user.name.length < 2) {
        alert(this.translate.instant('userManagement.user.missingName'));
        return;
      }

      if (this.isEdit) {
        await this.communication.updateUser(user);
      } else {
        await this.communication.addUser(user);
      }

      this.config.custom.complete.emit();

      this.dialog.close();
    });

    this.dialog.cancel.subscribe(() => {
      this.dialog.close();
    });

    this.organizations = this.config.custom.organizations;

    if (config.custom && config.custom.user) {
      this.name = config.custom.user.profile.name;
      this.firstname = config.custom.user.profile.firstname;
      this.organization = config.custom.user.profile.organization;
      this.email = config.custom.user.email;
      this.isEdit = true;
    }
  }
}
