import { Injectable } from '@angular/core'

import { BufferComponent } from '../components/page/pages/buffer/buffer.component'
import { ChapterComponent } from '../components/page/pages/chapter/chapter.component'
import { QuestionComponent } from '../components/page/pages/question/question.component'
import { ReminderComponent } from '../components/page/pages/reminder/reminder.component'
import { SearchComponent } from '../components/page/pages/search/search.component'

import { GuidanceComponent } from 'app/components/page/pages/guidance/guidance.component'
import { AssessmentComponent } from '../components/page/pages/assessment/assessment.component'
import { PageItem } from '../components/page/pages/page.item'
import { TextComponent } from '../components/page/pages/text/text.component'

@Injectable()
export class PageService {
  public availablePages: any

  constructor() {
    this.availablePages = this.getPages()
  }

  public isValid = (page: PnPage): boolean => {
    return (
      page
      && page.header
      && page.header.type
      && this.availablePages[page.header.type] !== undefined
    )
  }

  private getPages = () => {
    return {
      buffer: new PageItem(BufferComponent),
      question: new PageItem(QuestionComponent),
      reminder: new PageItem(ReminderComponent),
      chapter: new PageItem(ChapterComponent),
      search: new PageItem(SearchComponent),
      assessment: new PageItem(AssessmentComponent),
      guidance: new PageItem(GuidanceComponent),
      text: new PageItem(TextComponent)
    }
  }
}
