import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { ErrorService } from 'app/utils/error-handling/error.service'

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuardService implements CanActivate {

  constructor(private router: Router,
              private localData: LocalDataService,
              private errorService: ErrorService,
              private communication: CommunicationService) {
  }

  public async canActivate(): Promise<boolean> {
    const { country, language } = this.localData.getLocale()
    const initialSettings = !!(country && language)
    if (!initialSettings) return true
    else {
      try {
        const token = await this.communication.autoLogin()
        if (token) {
          await this.router.navigate(['/start-consultation'])
        } else {
          await this.router.navigate(['/select-tool'])
        }
        return false
      } catch (e) {
        // error will be thrown
        await this.errorService.handleError(e)
        return true
      }
    }
  }
}
