import { environment } from 'environments/environment'
import * as _ from 'lodash'
import * as pinoLogger from 'pino'
import { LogFn } from 'pino'

const pino = pinoLogger({
  level: environment.logLevel
})

// useful to bind logger to global to tweak log level at runtime
// tslint:disable:no-string-literal
window['pino'] = pino

/**
 * Log function , optionally taking object to be serialized
 */
export type LogFunc = (msg: string, obj?: any, ...interp: any[]) => void

const generateLogger = (level: string): LogFunc => (msg: string, obj?: any, ...interp: any[]) => {
  const isObject = obj !== null && obj !== undefined && typeof obj === 'object'
  const logFunc: LogFn = pino[level].bind(pino) as LogFn
  if (isObject) {
    logFunc(obj, msg, ...interp)
  } else if (obj) {
    logFunc(msg, obj, ...interp)
  } else {
    logFunc(msg, ...interp)
  }
}

/**
 * The trace is used to report structured log for usage in a log aggregator like LogStash or Splunk.
 * A uuid is attached to follow the trace along request path.
 *
 * @param args
 */
const traceFunc = (args: { level?: Level, tag?: string, uuid?: string, message: string, data?: any }, ...interp: any[]) => {
  const { level, tag, uuid, message, data } = _.merge({ level: 'info', tag: '<tag not set>', uuid: '<not set>' }, args)
  pino[level]({
    tag,
    info: { uuid, level: level.toUpperCase(), data }
  }, message, ...interp)
}

const loggers = {
  fatal: generateLogger('fatal'),
  // tslint:disable-next-line:object-literal-sort-keys
  error: generateLogger('error'),
  warn: generateLogger('warn'),
  info: generateLogger('info'),
  debug: generateLogger('debug'),
  trace: generateLogger('trace'),
  event: traceFunc,
  pino
}
export enum Level {
  FATAL = 'fatal',
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info',
  DEBUG = 'debug'
}
export const logger = loggers
export const event = traceFunc
