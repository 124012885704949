import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-question-compute',
  templateUrl: './question-compute.component.html',
  styleUrls: ['./question-compute.component.scss']
})
export class QuestionComputeComponent implements OnInit {
  @Input() public parentFormGroup
  @Input() public control
  @Input() public formSubmitAttempt
  @Input() public field
  @Input() public index: number
  @Input() public lastQuestion = false
  @Output() public readonly questionChangedAlert: EventEmitter<any> = new EventEmitter()
  @Output() public readonly scrollToNext: EventEmitter<any> = new EventEmitter()
  @Output() public readonly infoToggled: EventEmitter<number> = new EventEmitter()
  public computed = ""
  public message = ""

  computeMessages(score: number) {
    const newMessages: Array<string> = [];

    for (let message of this.field.messages) {
      if (message.condition.type === ">") {
        if (score > message.condition.value) {
          newMessages.push(message.message);
        }
      } else if (message.condition.type === ">=") {
        if (score >= message.condition.value) {
          newMessages.push(message.message);
        }
      } else if (message.condition.type === "<") {
        if (score < message.condition.value) {
          newMessages.push(message.message);
        }
      } else if (message.condition.type === ">=") {
        if (score <= message.condition.value) {
          newMessages.push(message.message);
        }
      } else if (message.condition.type === "==") {
        if (score === message.condition.value) {
          newMessages.push(message.message);
        }
      } else {
        throw Error('Unknown Condition Type');
      }
    }

    this.message = newMessages.length > 0 ? `(${newMessages.join(", ")})` : '';
  }

  ngOnInit(): void {
    this.parentFormGroup.valueChanges.subscribe(vals => {
      if (this.field.function === 'AVG') {
        let sum = 0;

        for (let i of this.field.dependsOnFieldIndices) {
          const value = vals[i];
          if (value !== '') {
            sum += +value;
          } else {
            return;
          }
        }

        const score = sum / this.field.dependsOnFieldIndices.length;
        this.computed = [this.field.prefix, score.toFixed(1)].join(": ");
        this.computeMessages(score);
      } else {
        throw Error('Unknown Function Type');
      }
    });
  }
}
