import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { logger } from 'app/utils/logger'
import { environment } from 'environments/environment'

export enum Event {
  BrowserNavAttempt = 'browser_nav_attempt',
  ForgetPasswordStart = 'forget_password_start',
  ForgetPasswordSuccess = 'forget_password_success',
  ForgetPasswordFail = 'forget_password_fail',
  LoginSuccess = 'login_success',
  LoginFail = 'login_fail',
  Start = 'start',
  ExtLinkOpen = 'external_link_open',
  MgmtPlan = 'mgmt_plan',
  MgmtPlanDowloadReport = 'mgmt_plan_download_report',
  MgmtPlanDowloadReferralLetter = 'mgmt_plan_download_referral_letter',
  MgmtPlanOpenAssessmentAccordion = 'mgmt_plan_open_assessment_accordion',
  GuidanceOpenAccordion = 'guidance_open_accordion',
  StepContinueError = 'step_continue_error',
  InhalerNotFound = 'inhaler_not_found',
  InhalerDontKnow = 'inhaler_dont_know',
  InhalerInLibrary = 'inhaler_in_library',
  RefreshAttempt = 'refresh_attempt',
  ConsultationAbort = 'consultation_abort',
  StepView = 'step_view',
  NavChosen = 'nav_chosen',
  StartConsultation = 'start_consultation',
  UpgradeToPlus = 'upgrade_to_plus',
  Logout = 'logout',
  SysErr = 'sys_error',
  AppErr = 'app_error',
  AppGeoblocked = 'app_error_geoblocked',
  FtuSkip = 'ftu_skip',
  FtuYes = 'ftu_yes',

  MgmtPlanDowloadReferralLetterManual = 'mgmt_plan_download_referral_letter_manual',
  SettingsEdit = 'settings_edited',
  SettingsSaved = 'settings_saved',
  emrSubmitSuccess = 'emr_submit_success',
  emrSubmitFail = 'emr_submit_fail'
}

export type Param =
  'user_country' | 'ref_id1' | 'user_language' | 'value1' | 'tool' | 'dmdp_user_id' | 'patient_language' | 'ref_id2' | 'value2'

export enum Prop {
  Country = 'user_country',
  Lang = 'user_language',
  Tool = 'tool',
  DmdpUserId = 'dmdp_user_id'
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private static readonly TAG = 'serv::Analytics'

  // We should make this configurable via ENV
  private enabled: boolean = environment.analytics.enabled
  private uaid: string = environment.analytics.uaid

  constructor(private router: Router) {
    logger.info('%s - constructor uaid: %s', AnalyticsService.TAG, this.uaid)
    gtag('config', this.uaid, {
      page_path: router.url,
      custom_map: {
        dimension1: 'user_country',
        dimension2: 'user_language',
        dimension3: 'value1',
        dimension4: 'ref_id1',
        dimension5: 'tool',
        dimension6: 'dmdp_user_id',
        dimension8: 'patient_language',
        dimension9: 'ref_id2',
        dimension10: 'value2'
      }
    })
  }

  public setProp(key: Prop, value: string | number | boolean) {
    if (this.enabled) {
      const config = { }
      config[key] = value
      gtag('set', config)
    } else {
      logger.info('%s - setProp(%s , %s)', AnalyticsService.TAG, key, value)
    }
  }

  public event(key: Event, params?: { [par in Param]?: string | number | boolean }) {
    if (this.enabled) {
      gtag('event', key, params)
    } else {
      logger.info('%s - event(%s , %o)', AnalyticsService.TAG, key, params)
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsServiceMock {

  private static readonly TAG = 'serv::AnalyticsMock'

  // We should make this configurable via ENV

  constructor() {
    logger.info('%s - constructor', AnalyticsServiceMock.TAG)
  }

  public setProp(key: string, value: string | number | boolean) {
    logger.info('%s - setProp(%s , %s)', AnalyticsServiceMock.TAG, key, value)
  }

  public event(key: string, params?: { [key: string]: string | number | boolean }) {
    logger.info('%s - event(%s , %o)', AnalyticsServiceMock.TAG, key, params)
  }
}
