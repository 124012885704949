import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, EventEmitter, Injectable, Injector, Type } from '@angular/core'
import { DialogConfig } from 'app/components/dialog/dialog-config'
import { DialogInjector } from 'app/components/dialog/dialog-injector'
import { DialogRef } from 'app/components/dialog/dialog-ref'
import { DialogComponent } from 'app/components/dialog/dialog.component'

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private dialogsOpen: Array<ComponentRef<DialogComponent>> = []

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
  }

  public open(componentType: Type<any>, config: DialogConfig): DialogRef {
    const { ref, componentRef } = this.appendDialogComponentToBody(config)
    componentRef.instance.childComponentType = componentType
    componentRef.instance.priority = false
    return ref
  }

  public openError(componentType: Type<any>, config: DialogConfig): DialogRef {
    const { ref, componentRef } = this.appendDialogComponentToBody(config)
    componentRef.instance.childComponentType = componentType
    componentRef.instance.priority = true
    // error dialog are forced to have error style
    componentRef.instance.errorStyle = true
    return ref
  }

  private appendDialogComponentToBody(config: DialogConfig): { ref: DialogRef, componentRef: ComponentRef<DialogComponent> } {
    const map = new WeakMap()

    const dialogRef = new DialogRef()
    map.set(DialogConfig, config)
    map.set(DialogRef, dialogRef)

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent)
    const componentRef = componentFactory.create(new DialogInjector(this.injector, map))
    this.appRef.attachView(componentRef.hostView)

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement
    document.body.appendChild(domElem)

    const actualComponent = componentRef.instance
    actualComponent.backDrop = config.backDrop
    actualComponent.title = config.title
    actualComponent.subTitle = config.subTitle
    actualComponent.content = config.content
    actualComponent.doneLabel = config.doneLabel
    actualComponent.cancelLabel = config.cancelLabel
    actualComponent.noPrimary = config.noPrimary
    actualComponent.withButtons = config.withButtons
    actualComponent.variant = config.variant
    actualComponent.errorStyle = config.errorStyle
    actualComponent.fixedHeight = config.fixedHeight

    dialogRef.cancel = actualComponent.cancel
    dialogRef.done = actualComponent.done

    // we want to know when somebody called the close mehtod
    const sub = dialogRef.afterClosed.subscribe(async () => {
      sub.unsubscribe()
      await actualComponent.animateOut()
      // close the dialog
      this.appRef.detachView(componentRef.hostView)

      // removing the componentRef from the array
      const index = this.dialogsOpen.indexOf(componentRef, 0)
      if (index > -1) {
        this.dialogsOpen.splice(index, 1)
      }
      this.fixVisibility()
      componentRef.destroy()
    })

    this.dialogsOpen.push(componentRef)
    this.fixVisibility()

    return { ref: dialogRef, componentRef }
  }

  private fixVisibility() {
    // nothing to do here
    if (this.dialogsOpen.length === 0) return

    this.dialogsOpen.forEach( (e, idx) => {
      const comp = e.instance
      comp.passive = true
      comp.first = false
    })

    this.dialogsOpen[0].instance.first = true
    this.dialogsOpen[this.dialogsOpen.length - 1].instance.passive = false
  }
}
