import { Injectable } from '@angular/core'
import { YES_NO } from 'app/utils/types'

@Injectable()
export class UtilitiesService {
  private questionChangedDialogShown = false
  private welcomeActive: boolean = false
  private secondaryCare?: YES_NO = null
  private activeGuidanceLink: {
    id: string
  } = null
  private assessmentNote: string = ''

  public setAssessmentNote(note) {
    this.assessmentNote = note
  }

  public getAssessmentNote() {
    return this.assessmentNote
  }

  public clearAssessmentNote() {
    this.assessmentNote = ''
  }

  public setWelcomeActive() {
    this.welcomeActive = true
  }

  public getWelcomeActive(): boolean {
    return this.welcomeActive
  }

  public clearWelcomeActive() {
    this.welcomeActive = false
  }

  public setSecondaryCare(data: YES_NO) {
    this.secondaryCare = data
  }

  public getSecondaryCare(): YES_NO {
    return this.secondaryCare
  }

  public clearSecondaryCare() {
    this.secondaryCare = null
  }

  public setGuidanceSection(link: { id: string }) {
    this.activeGuidanceLink = link
  }

  public getGuidanceSection(): { id: string}  {
    return this.activeGuidanceLink
  }

  public clearGuidanceSection() {
    this.activeGuidanceLink = null
  }

  public setQuestionChangedDialog(value: boolean) {
    this.questionChangedDialogShown = value
  }

  public getQuestionChangedDialog(): boolean {
    return this.questionChangedDialogShown
  }
}
