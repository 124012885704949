export class Input {
  public id: string
  public value: any
}

export class InputOption extends Input {
  public label: string
}

export const YES: InputOption = {
  id: 'YES',
  label: 'Yes',
  value: true
}

export const NO: InputOption = {
  id: 'NO',
  label: 'No',
  value: false
}

export type YES_NO = {
  id: 'YES',
  label: 'Yes',
  value: true
} | {
  id: 'NO',
  label: 'No',
  value: false
}
