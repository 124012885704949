import { Component } from '@angular/core'
import { BuildInfo, CommunicationService } from 'app/services/communication.service'
import { environment } from 'environments/environment'

@Component({
  selector: 'app-version-panel',
  templateUrl: './version-panel.component.html',
  styleUrls: ['./version-panel.component.scss']
})
export class VersionPanelComponent {
  public pwa: string = '-'
  public mdw: string = '-'
  public variant: string = '-'
  constructor(
    private communicate: CommunicationService
  ) {
    this.pwa = [environment.version, String(environment.buildno)].join(' + ')
    this.communicate.buildInfo().subscribe(v => {
      this.mdw = [v.version, String(v.buildno)].join(' + ')
      this.variant = v.variant
    })
  }
}
