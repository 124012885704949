import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core'
import { LocalDataService } from 'app/services/local-data.service'

@Component({
  selector: 'app-input-toggle',
  templateUrl: './input-toggle.component.html',
  styleUrls: ['../input-number/input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputToggleComponent implements OnInit, AfterViewInit {
  @Input() public parentFormGroup
  @Input() public control
  @Input() public formSubmitAttempt
  @Input() public field
  public min
  public max
  public input
  public placeholder
  public label
  public options
  public metric
  public decimal
  public defaultUnit = true
  private isDotSeparator: boolean
  private decimalSeparator: string
  public unitSystem: string

  @ViewChild('inputElement', { static: false }) private inputEl: ElementRef

  constructor(private cdr: ChangeDetectorRef,
              private localData: LocalDataService) {
  }

  public ngOnInit() {
    this.min = this.field.min || 0
    this.max = this.field.max
    this.input = this.field.input
    this.placeholder = this.field.placeholder
    this.label = this.field.label
    this.options = this.field.options
    this.metric = this.field.metric
    this.decimal = parseInt(this.field.decimal, 10)
    this.isDotSeparator = this.whatDecimalSeparator()
    this.decimalSeparator = this.isDotSeparator ? '.' : ','
    this.unitSystem =  this.getUnitSystem()
  }

  public getUnitSystem() {
    const countryCode = this.localData.getLocale().country.toUpperCase()
    switch (countryCode) {
      case 'US':
      case 'LR':
      case 'MM':
      case 'GB':
        return 'BOTH'
      default:
        return 'METRIC'
    }
  }

  public ngAfterViewInit(): void {
    // convert the displayed height value from meters to cm
    if (this.metric === 'height' && !!this.inputEl && !!this.inputEl.nativeElement.value) {
      this.inputEl.nativeElement.value = +this.inputEl.nativeElement.value * 100
      setTimeout(() => this.cdr.markForCheck(), 0)
    }
  }

  public allowOnlyNumbers(event) {
    let value = event.target.value.replace(/,/g, '.')
    const eventData = event.target.value.substring(value.length - 1)
    const amountOfDecimals = this.countDecimals(value)
    const decimalSeparators = this.inputEl.nativeElement.value.replace(/,/g, '.').split('.').length - 1
    if (isNaN(eventData) && eventData !== '.' && eventData !== ','
            || isNaN(eventData) && this.decimal === 0
            || amountOfDecimals > this.decimal
            || eventData === ' '
            || eventData === '.' && decimalSeparators > 1
            || eventData === ',' && decimalSeparators > 1
            || eventData === '.' && this.metric === 'height' && this.defaultUnit
            || eventData === ',' && this.metric === 'height' && this.defaultUnit
            || eventData === '.' && this.metric === 'weight' && !this.defaultUnit
            || eventData === ',' && this.metric === 'weight' && !this.defaultUnit) {
      const pos = event.target.value.lastIndexOf(eventData)
      value = value.substring(0, pos) + value.substring(pos + 1)
    }

    if ( this.decimal === 0 && +value || +value === this.min) {
        this.setControlValue(event.target.value)
    } else if (this.decimal !== 0 && +value || +value === this.min) {
        this.convertValue(+value)
    } else {
        this.control.setErrors({ incorrect: true })
    }

    value = value.replace('.', this.decimalSeparator)
    event.target.value = value
  }

  public increaseValue() {
    const val = (this.inputEl.nativeElement.value).replace(/,/g, '.')
    if (!val) {
      const res = this.getConvertedMin()
      this.inputEl.nativeElement.value = res.toString().replace('.', this.decimalSeparator)
      this.setControlValue(+res)
    } else if (+val < this.getConvertedMax() && +val >= this.getConvertedMin()) {
      let step = (!!this.min && this.min <= 1) ? this.min : 1
      step = this.decimal ? (1 / (Math.pow(10, this.decimal))) : step
      if (this.metric === 'height' && this.defaultUnit || this.metric === 'weight' && !this.defaultUnit) {
        step = 1
      }
      const res = Math.round((+val + step) * 1e12) / 1e12
      this.inputEl.nativeElement.value = this.isDotSeparator ? +res : res.toString().replace('.', ',')
      this.convertValue(+res)
    } else if (+val < this.getConvertedMin()) {
      this.inputEl.nativeElement.value = this.getConvertedMin().toString().replace('.', ',')
      this.setControlValue(this.min)
    }
  }

  public decreaseValue() {
    const val = (this.inputEl.nativeElement.value).replace(/,/g, '.')
    if (!val) {
      const res = this.getConvertedMin()
      this.inputEl.nativeElement.value = res.toString().replace('.', this.decimalSeparator)
      this.setControlValue(+res)
    } else if (+val > this.getConvertedMin() && +val < this.getConvertedMax()) {
      let step = (!!this.min && this.min <= 1) ? this.min : 1
      step = this.decimal ? (1 / (Math.pow(10, this.decimal))) : step
      if (this.metric === 'height' && this.defaultUnit || this.metric === 'weight' && !this.defaultUnit) {
        step = 1
      }
      const res = Math.round((+val - step) * 1e12) / 1e12
      this.inputEl.nativeElement.value = this.isDotSeparator ? +res : res.toString().replace('.', ',')
      !!this.metric ? this.convertValue(+res) : this.setControlValue(+res)
    } else if (+val > this.getConvertedMax()) {
      this.inputEl.nativeElement.value = this.getConvertedMax().toString().replace('.', ',')
      this.setControlValue(this.max)
    }
  }

  private setControlValue(val) {
    this.control.value = val
    this.control.pristine = false
    this.control.touched = true
    this.parentFormGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true })
    if (val <= this.max && val >= this.min) {
      this.control.setErrors(null)
    }
  }

  public onBlur(e) {
    const value = e.target.value.replace(/,/g, '.')
    if (+value < this.getConvertedMin() && value !== '') {
      this.control.setErrors({ min: true })
    } else if (+value > this.getConvertedMax() && value !== '') {
      this.control.setErrors({ max: true })
    } else if (!(+value) && value !== this.min.toString()) {
      this.control.setErrors({ required: true })
    }
  }

  private roundNumber(nr) {
    if (!!nr) {
      // const decimalDigits = ((this.metric === 'height' && this.defaultUnit) || (this.metric === 'weight' && !this.defaultUnit)) ? 0 : this.decimal
      return Math.round(nr * Math.pow(10, this.decimal)) / Math.pow(10, this.decimal)
    } else if (nr === 0) {
      return 0
    }
  }

  private countDecimals(value): number {
    const array = value.split('.')
    return !!array[1] ? array[1].length : 0
  }

  public toggleUnit(isDefault) {
    const val = (this.inputEl.nativeElement.value).replace(/,/g, '.')
    if (!!val && isDefault !== this.defaultUnit || val === 0 && isDefault !== this.defaultUnit) {
      this.defaultUnit = isDefault
      const res = this.convertDisplayedValue(+val)
      this.inputEl.nativeElement.value = this.isDotSeparator ? res : res.toString().replace('.', ',')
    } else if (!val && val !== 0) {
      this.defaultUnit = isDefault
    }
  }

  private convertDisplayedValue(e): number {
    if (this.metric === 'height' && this.defaultUnit) {
      return Math.round(e * 2.54)
    } else if (this.metric === 'height' && !this.defaultUnit) {
      return this.roundNumber(e / 2.54)
    } else if (this.metric === 'weight' && this.defaultUnit) {
      return this.roundNumber(e / 2.205)
    } else if (this.metric === 'weight' && !this.defaultUnit) {
      return Math.round(e * 2.205)
    }
  }

  public getConvertedMin() {
    if (this.metric === 'height' && this.defaultUnit) {
      return this.min * 100
    } else if (this.metric === 'height' && !this.defaultUnit) {
      return +(this.min * 39.37).toFixed(this.decimal)
    } else if (this.metric === 'weight' && this.defaultUnit) {
      return this.min
    } else if (this.metric === 'weight' && !this.defaultUnit) {
      return +(this.min * 2.205).toFixed(this.decimal)
    }
  }

  public getConvertedMax() {
    if (this.metric === 'height' && this.defaultUnit) {
      return this.max * 100
    } else if (this.metric === 'height' && !this.defaultUnit) {
      return +(this.max * 39.37).toFixed(this.decimal)
    } else if (this.metric === 'weight' && this.defaultUnit) {
      return this.max
    } else if (this.metric === 'weight' && !this.defaultUnit) {
      return +(this.max * 2.205).toFixed(this.decimal)
    }
  }

  public convertValue(e) {
    if (this.metric === 'height' && this.defaultUnit) {
      this.setControlValue(this.roundNumber(e / 100))
    } else if (this.metric === 'height' && !this.defaultUnit) {
      this.setControlValue(this.roundNumber(e / 39.37))
    } else if (this.metric === 'weight' && this.defaultUnit) {
      this.setControlValue(this.roundNumber(e))
    } else if (this.metric === 'weight' && !this.defaultUnit) {
      this.setControlValue(this.roundNumber(e / 2.205))
    }
  }

  public getTemplateLabel(pos, label) {
    const parts: string[] = label.split('$0')
    const pre = parts[0] ? (parts[0]) : ''
    const post = parts[1] ? (parts[1]) : ''
    return pos === 'pre' ? pre : post
  }

  private whatDecimalSeparator(): boolean {
    const n = 1.1
    return n.toLocaleString(this.localData.getLocale().country).toString().substring(1, 2) === '.'
  }

  public onPaste() {
    return this.input === 'text'
  }

}
