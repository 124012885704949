import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { ashtmaNavigator, referralIdentifier } from 'app/interfaces/theme.interface'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { NavigationService } from 'app/services/navigation.service'
import { ThemeService } from 'app/services/theme.service'
import { ErrorService } from 'app/utils/error-handling/error.service'

@Injectable({
  providedIn: 'root'
})
export class SelectToolGuardService implements CanActivate {
  public referralIdentifier: string = referralIdentifier.name
  public asthmaNavigator: string = ashtmaNavigator.name
  constructor(private router: Router,
              private localData: LocalDataService,
              private translate: TranslateService,
              private navigation: NavigationService,
              private errorService: ErrorService,
              private themeService: ThemeService,
              private communication: CommunicationService) {
  }

  public async canActivate(): Promise<boolean> {
    const { country, language } = this.localData.getLocale()
    const initialSettings = !!(country && language)
    if (!initialSettings) {
      this.navigation.logout()
      this.localData.clear()
      await this.router.navigate(['/'])
      return false
    } else {
      this.localData.setLocale({ country, language })
      try {
        const tools = await this.communication.getToolForCountry(country.toLowerCase())
        if (!tools) {
          this.navigation.logout()
          this.localData.clear()
          await this.router.navigate(['/'])
          return false
        } else if (tools.length > 1) {
          try {
            const token = await this.communication.autoLogin()
            if (token) {
              await this.router.navigate(['/start-consultation'])
              return false
            } else {
              return true
            }
          } catch (e) {
            await this.errorService.handleError(e)
            return false
          }
        } else if (!!this.localData.getLastActiveTool() && !tools.includes(this.localData.getLastActiveTool())) {
          this.navigation.logout()
          this.localData.clear()
          await this.router.navigate(['/'])
        } else if (tools.includes(this.asthmaNavigator) || tools.includes(this.referralIdentifier)) {
          this.themeService.updateTheme(tools[0])
          this.localData.setLastActiveTool(tools[0])
          await this.router.navigate(['/login'])
          return false
        }
        return true
      } catch (e) {
        await this.errorService.handleError(e)
        return false
      }
    }
  }
}
