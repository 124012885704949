import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { LocalDataService } from 'app/services/local-data.service'
import {toNumber} from "lodash";

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit {
  @Input() public parentFormGroup
  @Input() public control
  @Input() public formSubmitAttempt
  @Input() public field
  public min
  public max
  public input
  public placeholder
  public label
  public options
  public decimal
  private isDotSeparator: boolean
  private decimalSeparator: string

  @ViewChild('inputElement', { static: false }) private inputEl: ElementRef

  constructor(private cdr: ChangeDetectorRef,
              private localData: LocalDataService) { }

  public ngOnInit() {
    this.min = this.field.min || 0
    this.max = this.field.max
    this.input = this.field.input
    this.placeholder = this.field.placeholder
    this.label = this.field.label
    this.options = this.field.options
    this.decimal = parseInt(this.field.decimal, 10)
    this.isDotSeparator = this.whatDecimalSeparator()
    this.decimalSeparator = this.isDotSeparator ? '.' : ','
  }

  public allowOnlyNumbers(event) {
    let value = event.target.value.replace(/,/g, '.')
    const eventData = event.target.value.substring(value.length - 1)
    const amountOfDecimals = this.countDecimals(value)
    const decimalSeparators = this.inputEl.nativeElement.value.replace(/,/g, '.').split('.').length - 1
    if (isNaN(eventData) && eventData !== '.' && eventData !== ','
            || isNaN(eventData) && this.decimal === 0
            || amountOfDecimals > this.decimal
            || eventData === ' '
            || eventData === '.' && decimalSeparators > 1
            || eventData === ',' && decimalSeparators > 1) {
      const pos = event.target.value.lastIndexOf(eventData)
      value = value.substring(0, pos) + value.substring(pos + 1)
    }

    if (+value || +value === this.min) {
      this.setControlValue(+value)
    } else {
      this.control.setErrors({ incorrect: true })
    }

    value = value.replace('.', this.decimalSeparator)
    event.target.value = value
  }

  public increaseValue() {
    const val = (this.inputEl.nativeElement.value).replace(/,/g, '.')
    let step = (!!this.min && this.min <= 1) ? this.min : 1
    step = this.decimal ? (1 / (Math.pow(10, this.decimal))) : step
    if (!val) {
      const res = !!this.placeholder ? parseFloat(this.placeholder.replace(',', '.')) : (this.min === 0 ? 0 : (this.min > step ? this.min : step))
      this.inputEl.nativeElement.value = res.toString().replace('.', this.decimalSeparator)
      this.setControlValue(res)
    } else if (!this.max || this.max && +val < this.max) {
      const res = Math.round((+val + step) * 1e12) / 1e12
      this.inputEl.nativeElement.value = this.isDotSeparator ? +res : res.toString().replace('.', ',')
      this.setControlValue(+res)
    }
  }

  public decreaseValue() {
    const val = (this.inputEl.nativeElement.value).replace(/,/g, '.')
    let step = (!!this.min && this.min <= 1) ? this.min : 1
    step = this.decimal ? (1 / (Math.pow(10, this.decimal))) : step
    if (!val) {
      const res = !!this.placeholder ? parseFloat(this.placeholder.replace(',', '.')) : (this.min === 0 ? 0 : (this.min > step ? this.min : step))
      this.inputEl.nativeElement.value = res.toString().replace('.', this.decimalSeparator)
      this.setControlValue(+res)
    } else if ((this.min || this.min === 0) && +val > this.min || (!this.min && this.min !== 0)) {
      const res = Math.round((+val - step) * 1e12) / 1e12
      this.inputEl.nativeElement.value = this.isDotSeparator ? +res : res.toString().replace('.', ',')
      this.setControlValue(+res)
    }
  }

  private setControlValue(val) {
    this.control.value = val
    this.control.pristine = false
    this.control.touched = true
    this.parentFormGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true })
    if (val <= this.max && val >= this.min) {
      this.control.setErrors(null)
    }
  }

  public onBlur(e) {
    const value = e.target.value.replace(/,/g, '.')
    if (+value < this.min && value !== '') {
      this.control.setErrors({ min: true })
    } else if (+value > this.max && value !== '') {
      this.control.setErrors({ max: true })
    } else if (!(+value) && value !== this.min.toString()) {
      this.control.setErrors({ required: true })
    }
  }

  private roundNumber(nr) {
    if (!!nr) {
      return Math.round(nr * Math.pow(10, this.decimal)) / Math.pow(10, this.decimal)
    } else if (nr === 0) {
      return 0
    }
  }

  private countDecimals(value): number {
    const array = value.split('.')
    return !!array[1] ? array[1].length : 0
  }

  public getTemplateLabel(pos, label) {
    const parts: string[] = label.split('$0')
    const pre = parts[0] ? (parts[0]) : ''
    const post = parts[1] ? (parts[1]) : ''
    return pos === 'pre' ? pre : post
  }

  private whatDecimalSeparator(): boolean {
    const n = 1.1
    return n.toLocaleString(this.localData.getLocale().country).toString().substring(1, 2) === '.'
  }

  public onPaste() {
    return this.input === 'text'
  }

}
