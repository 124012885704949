import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { logger } from 'app/utils/logger'
import Amplify from 'aws-amplify'
import { throwError } from 'rxjs'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
  enableProdMode()
}

(async () => {
  // Load configuration
  try {
    const response = await fetch('assets/env-config.json')
    const config = await response.json()
    logger.info('Load remote config %o', null, config)
    Object.assign(environment, config)
  } catch (e) {
    logger.info('no config present')
  }

  // merging all properties
  logger.pino.level = environment.logLevel

  // Tool configurations
  Amplify.configure(environment.amplify)
  if (environment.production) {
    enableProdMode()
  }
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => throwError(err))
})()
