import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { IllegalArgError, Nullable } from 'app/utils/lang-utils'
import { environment } from 'environments/environment'

interface TCData {
  termsAndConditionsUrl: string
  privacyNoticeUrl: string
}

export interface LocaleInput {
  country?: string
  language?: string
}

interface LocaleSpec {
  country: string
  language: string
}

/**
 * Service to store in the local storage values that need to
 * be cleared together with login session.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalDataService {

  constructor(private translate: TranslateService) { }

  public setFirstTimeTour() {
    localStorage.setItem('firstTimeTour', 'true')
  }

  public getFirstTimeTour() {
    return JSON.parse(localStorage.getItem('firstTimeTour'))
  }

  public setLocale({ country, language }: LocaleInput): LocalDataService {
    if (typeof country === 'string') {
      localStorage.setItem('country', JSON.stringify(country.toUpperCase()))
    }
    if (typeof language === 'string') {
      localStorage.setItem('language', JSON.stringify(language.toLowerCase()))
    }
    this.translate.use(language || this.getLocale().language);
    environment.language = language
    return this
  }

  public getLocale(): LocaleSpec {
    const country: string = JSON.parse(localStorage.getItem('country'))
    // if (typeof country !== 'string') {
    //   country = 'GB'
    //   localStorage.setItem('country', country)
    // }
    const language = JSON.parse(localStorage.getItem('language'))
    // if (typeof language !== 'string') {
    //   language = 'en'
    //   localStorage.setItem('language', language)
    // }
    if (language === 'ja') {
      document.body.classList.add('lang-ja')
    } else {
      document.body.classList.remove('lang-ja')
    }
    return  {
      country,
      language
    }
  }

  public clearLocalConfirmPass() {
    localStorage.removeItem('language')
  }

  public setCountryOverride(value: boolean) {
    localStorage.setItem('countryOverride', JSON.stringify(value))
  }

  public getCountryOverride(): boolean {
    return !!JSON.parse(localStorage.getItem('countryOverride'))
  }

  public setLastActiveTool(value: string) {
    localStorage.setItem('lastActiveTool', JSON.stringify(value))
  }
  public clearLastActiveTool() {
    localStorage.removeItem('lastActiveTool')
  }

  public getLastActiveTool(): string {
    return JSON.parse(localStorage.getItem('lastActiveTool'))
  }

  public setCookiesAccepted(value: boolean) {
    localStorage.setItem('cookiesAccepted', JSON.stringify(value))
  }

  public getCookiesAccepted(): boolean {
    return !!JSON.parse(localStorage.getItem('cookiesAccepted'))
  }

  public setRecentInhalers(data) {
    localStorage.setItem('searchRecentInhalers', JSON.stringify(data))
  }

  public getRecentInhalers() {
    return JSON.parse(localStorage.getItem('searchRecentInhalers'))
  }

  public clearRecentInhalers() {
    localStorage.removeItem('searchRecentInhalers')
  }

  public setUserDetail(data: UserDetail) {
    localStorage.setItem('user', JSON.stringify(data))
  }

  public getUserDetail(): Nullable<UserDetail> {
    const jsonString = localStorage.getItem('user')
    if (jsonString == null) return null
    return parseUserDetail(jsonString)
  }

  public clearUserDetail() {
    localStorage.removeItem('user')
  }

  public getEdifactAddress() {
    return JSON.parse(localStorage.getItem('edifact'))
  }

  public setEdifactAddress(edifact: string) {
    localStorage.setItem('edifact', JSON.stringify(edifact))
  }

  public clearEdifactAddress() {
    localStorage.removeItem('edifact')
  }

  public clear() {
    const cookies = this.getCookiesAccepted()
    localStorage.clear()
    this.setCookiesAccepted(cookies)
  }
}

function parseUserDetail(src: string): UserDetail {
  if (typeof src !== 'string') {
    throw new IllegalArgError('User details content is invalid!')
  }
  const obj = JSON.parse(src)
  if (typeof obj !== 'object') {
    throw new IllegalArgError('User details not an object!')
  }
  if (isUserDetails(obj)) {
    return obj
  }
  throw new IllegalArgError('Not really an user detail!')
}

export function isUserDetails(obj: any): obj is UserDetail {
  return obj != null &&
    typeof obj.id === 'string' &&
    typeof obj.firstName === 'string' &&
    typeof obj.lastName === 'string' &&
    typeof obj.email === 'string' &&
    typeof obj.role === 'string' &&
    typeof obj.group === 'string' &&
    typeof obj.country === 'string' &&
    typeof obj.countryCode === 'string'
}
