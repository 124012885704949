import { Component, Directive, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @ViewChild('notes', {
    static: false
  }) notes: ElementRef<HTMLInputElement>;
  @Input() public parentFormGroup: FormGroup
  @Input() public control: any
  @Input() public option: any
  @Input() public checked: boolean
  @Input() public disabled = false
  @Input() public small: false
  @Input() public notesEnabled: false
  @Output() public readonly onChange = new EventEmitter<any>()
  @Output() public readonly onNoteChange = new EventEmitter<any>()

  public onValueChange(ev) {
    if (!ev.target.checked) {
      if (this.notes) {
        this.notes.nativeElement.value = "";
      }
    }

    this.onChange.emit(ev)
  }

  public onNoteValueChange(ev) {
    this.onNoteChange.emit(ev)
  }
}

/**
 * `<checkbox-label>`
 *
 * This directive is to be used inside of the CheckboxComponent component.
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'checkbox-label'
})
export class AppCheckboxLabelDirective {
  @HostBinding('class') public class = 'app-checkbox_label'
}
