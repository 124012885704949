import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { NavigationEnd, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { DialogConfig } from 'app/components/dialog/dialog-config'
import { DialogRef } from 'app/components/dialog/dialog-ref'
import { DialogService } from 'app/components/dialog/dialog.service'
import { AnalyticsService, Event } from 'app/services/analytics'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { NavigationService } from 'app/services/navigation.service'

export enum NavBackBarVariant {
  DEFAULT,
  ONBOARDING,
  SIGNUP,
  QUESTIONNAIRE
}

@Component({
  selector: 'app-back-navigation-bar',
  templateUrl: './back-navigation-bar.component.html',
  styleUrls: ['./back-navigation-bar.component.scss']
})
export class BackNavigationBarComponent {
  @Input() public variant = NavBackBarVariant.DEFAULT
  @Input() private navBar: any
  @Input() public hasBack: boolean = true
  @Input() public customBackAction: boolean = false
  @Input() public forceAlert: boolean = false
  @Output() public readonly backAction?: EventEmitter<any> = new EventEmitter<any>()
  private endConsultationDialog: DialogRef = null
  private settingsDialog: DialogRef = null
  private dataLossDialog: DialogRef = null
  public navBackBarVariant = NavBackBarVariant
  public showSettings: boolean = false
  public showSettingsLink: boolean = true
  public showUserManagement: boolean = false
  public showUserManagementLink: boolean = true
  public showLogout: boolean = true
  public showAbout: boolean = true

  constructor(
    private navigate: NavigationService,
    private router: Router,
    private localData: LocalDataService,
    private analyticsService: AnalyticsService,
    private communication: CommunicationService,
    private titleService: Title,
    private translate: TranslateService,
    private dialog: DialogService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showSettings = !!this.localData.getUserDetail()
        this.showUserManagement = !!this.localData.getUserDetail() && this.localData.getUserDetail().group === "localadmin" && event.url !== '/user-management' && event.url !== '/questionnaire'
        this.showSettingsLink = event.url !== '/settings'
        this.showAbout = event.url !== '/about' && event.url !== '/questionnaire'
        if (event.url === '/questionnaire') {
          this.showLogout = false
        }
      }
    })
  }

  public logout() {
    this.navigate.logout()
    this.router.navigate(['/login'])
    this.analyticsService.event(Event.Logout)
  }

  public goToUserManagement() {
    this.router.navigate(['/user-management']);
  }

  public goToSettings() {
    if (this.router.url !== '/questionnaire') {
      this.router.navigate(['/settings'])
    } else {
      const config = new DialogConfig<any>()
      config.backDrop = false
      config.title = this.translate.instant('navBar.settingsDialogTitle')
      config.subTitle = this.translate.instant('navBar.settingsDialogContent')
      config.doneLabel = this.translate.instant('navBar.settingsDialogYes')
      config.cancelLabel = this.translate.instant('navBar.settingsDialogNo')
      this.settingsDialog = this.dialog.open(null, config)
      this.settingsDialog.done.subscribe(() => {
        this.settingsDialog.close()
        this.settingsDialog = null
        // this.navigate.resetContext()
        this.router.navigate(['/settings'])
      })
      this.settingsDialog.cancel.subscribe(() => {
        this.settingsDialog.close()
      })
    }
  }

  public goToAbout() {
    this.router.navigate(['/about'])
  }

  public endSession() {
    const config = new DialogConfig<any>()
    config.backDrop = false
    config.title = this.translate.instant('assessment.exitConsultationTitle')
    config.subTitle = this.translate.instant('assessment.exitConsultationContent')
    config.doneLabel = this.translate.instant('assessment.exitConsultationYes')
    config.cancelLabel = this.translate.instant('assessment.exitConsultationNo')
    this.endConsultationDialog = this.dialog.open(null, config)
    this.endConsultationDialog.done.subscribe(() => {
      this.endConsultationDialog.close()
      this.endConsultationDialog = null
      this.navigate.resetContext()
      this.router.navigate(['/start-consultation'])
    })
    this.endConsultationDialog.cancel.subscribe(() => {
      this.endConsultationDialog.close()
    })
  }

  public async goBack() {
    if (this.navBar.back.action === 'getPage' && this.navBar.back.direction === 'prev') {
      this.navigate.prevPage({ })
    } else if (this.navBar.back.action === '/') {
      const { country, language } = this.localData.getLocale()
      this.localData.clear()
      await this.router.navigate(['/'], { queryParams: { country, language } })
      this.titleService.setTitle('')
    } else if (this.router.url === '/start-consultation' && this.navBar.back.action === '/login') {
      this.navigate.logout()
      await this.router.navigate(['/login'])
    } else if ((this.router.url === '/sign-up' ||
      this.router.url.startsWith('/confirm') || this.router.url.startsWith('/signup-complete'))
      && !this.forceAlert) {
      await this.router.navigate([this.navBar.back.action])
    } else if (this.router.url === '/login' && this.navBar.back.action === '/select-tool') {
      const { country, language } = this.localData.getLocale()
      const tools = await this.communication.getToolForCountry(country.toLowerCase())
      if (tools.length > 1 ) {
        await this.router.navigate(['/select-tool'])
      } else {
        this.localData.clear()
        await this.router.navigate(['/'],   { queryParams: { country, language } })
        this.titleService.setTitle('')
      }
    } else {
      this.throwAlert()
    }
  }

  private throwAlert() {
    const config = new DialogConfig<any>()
    config.backDrop = true
    config.title = this.translate.instant('exitAssessment.title')
    config.subTitle = this.translate.instant('exitAssessment.body')
    config.doneLabel = this.translate.instant('exitAssessment.confirm')
    config.cancelLabel = this.translate.instant('exitAssessment.cancel')
    this.dataLossDialog = this.dialog.open(null, config)
    this.dataLossDialog.done.subscribe(() => {
      this.navigateOutOfQuestionnaire()
      this.analyticsService.event(Event.ConsultationAbort)
      this.dataLossDialog.close()
    })
    this.dataLossDialog.cancel.subscribe(() => {
      this.dataLossDialog.close()
    })
  }

  private navigateOutOfQuestionnaire() {
    if (this.navBar.back.action === '/start-consultation') {
      this.router.navigate(['/start-consultation'], { replaceUrl: true })
      this.titleService.setTitle('start-consultation')
    } else if (this.navBar.back.action === '/login') {
      this.navigate.logout()
      this.router.navigate(['/login'], { replaceUrl: true })
    }
  }
}
