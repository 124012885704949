import {
  Component,
  EventEmitter,
  Input, OnDestroy,
  Output
} from '@angular/core'
import { NavigationService } from 'app/services/navigation.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import * as _ from 'lodash'
import { fromEvent, Observable, Subject } from 'rxjs'
import { map, takeUntil, throttleTime } from 'rxjs/operators'

export enum AlertVariant {
  Default,
  NoPadding,
  Secondary
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    FadeInAnimation.animations
  ]
})
export class AlertComponent implements OnDestroy {
  public alertVariant = AlertVariant
  private timer
  private ONSCROLL$: Observable<any>
  private unsubscribe$ = new Subject()
  @Input() public content: any
  @Input() public snapLayout: boolean
  @Input() public startAnimation = false
  @Input() public variant: AlertVariant = AlertVariant.Default
  @Input() private output: any
  @Input() private canSubmit: boolean
  @Input() private stepId: string
  @Output() public readonly submitAttempt = new EventEmitter<any>()
  @Input() set show(value: boolean) {
    this._show = value
    this.animationTriggered(this._show)
  }

  get show(): boolean {
    return this._show
  }
  private _show: boolean
  constructor( private navigate: NavigationService) { }

  private animationTriggered(show: boolean) {
    // wait for the autoscroll to finish
    // before triggering the animation
    // to avoid conflicts
    if (this.snapLayout) {
      this.timer = setTimeout(() => {
        this.startAnimation = show
      }, 320) // 300ms is the delay before the autoscroll kicks in
      this.onScroll$.pipe(takeUntil(this.unsubscribe$)).subscribe(d => {
       clearTimeout(this.timer)
       this.timer = setTimeout(() => {
         this.startAnimation = show
       }, 150)
     })
    } else {
      this.startAnimation = show
    }
  }

  get onScroll$() {
    const scrollingEl = document.querySelector('.question-wrapper')
    this.ONSCROLL$ = fromEvent(scrollingEl, 'scroll').pipe(
                    throttleTime(50),
                    map( () => scrollingEl.scrollTop))
    return this.ONSCROLL$
  }

  public jumpTo(): void {
    const data = {
      answer: {
        toStepId: this.stepId,
        inputs: this.output
      },
      context: null
    }
    if (this.canSubmit && _.get(this.content, 'cta.direction')) {
      this.navigate.jumpTo(this.content.cta.direction, data)
    } else {
      this.submitAttempt.emit(this.content.cta)
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
