import { Pipe, PipeTransform } from '@angular/core'
import * as marked from 'marked'

@Pipe({ name: 'md' })
export class MdPipe implements PipeTransform {

  public transform(value: string): string {
    if (value && value.length > 0) {
      return marked.inlineLexer(value, [])
    }
    return value
  }
}
