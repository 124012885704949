import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateLoader } from '@ngx-translate/core'
import { Observable, of, throwError } from 'rxjs'
import { catchError, concatMap, flatMap, map } from 'rxjs/operators'
import { logger } from './logger'

@Injectable()
export class PNTTranslateLoader implements TranslateLoader {
  private static readonly TAG = 'serv::PNTTranslateLoader'
  constructor(private http: HttpClient) { }

  private getTransPath(s: string): Observable<any> {
    logger.debug('%s - getTransPath for %s', PNTTranslateLoader.TAG, s)
    return this.http.get(`/assets/i18n/${s}.json`)
  }

  public getTranslation(lang: string): Observable<any> {
    // lang is of language-region type defined in rfc5646, i.e. 'pt-IT'
    return this.getTransPath(lang).pipe(
      catchError(
        err => {
          // will fallback only on 404s
          if (err.status === 404) {
            // trying to fallback to generic language pt-IT -> pt
            const chunks = lang.split('-')
            if (chunks.length > 1) {
              chunks.pop()
              return this.getTransPath(chunks.join('-'))
            }
          }
          return throwError(err)
        }),
      catchError(err => err.status === 404 ? this.getTransPath('en') : throwError(err)),
      concatMap(values => {
        if (values._extends == null) return of(values)
        // if a base translation is specified, then load and overwrite values by their key
        return this.getTranslation(values._extends).pipe(
          concatMap(baseValues => of(Object.assign(baseValues, values)))
        )
      }))
  }
}
