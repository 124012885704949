import { Component, OnDestroy, OnInit } from '@angular/core'
import { LoaderState } from 'app/interfaces/loader.interface'
import { LoaderService } from 'app/services/loader.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  public show = false
  private subscription: Subscription
  constructor(private loaderService: LoaderService) { }

  public ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
      this.show = state.show
    })
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe()
  }

}
