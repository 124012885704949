import {
  HttpErrorResponse,
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CommunicationService } from 'app/services/communication.service'
import { LoaderService } from 'app/services/loader.service'
import { Auth } from 'aws-amplify'
import { promisify } from 'es6-promisify'
import { from, Observable, Subject, throwError } from 'rxjs'
import { catchError, retry, switchMap, tap } from 'rxjs/operators'
import { Router } from '@angular/router'

// This class intercepts every http error and trigger
// the global-error-handler.

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  private timer
  private refreshSubject: Subject<any> = new Subject<any>()
  constructor(private loaderService: LoaderService,
              private communication: CommunicationService,
              private router: Router
    ) {
  }

  private async refreshToken() {
    const currentSession = await Auth.currentSession()
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const refreshSession = promisify(cognitoUser.refreshSession.bind(cognitoUser))
    const { idToken } = await refreshSession(currentSession.getRefreshToken())
    await this.communication.patientLogin(idToken.jwtToken)
  }

  private ifTokenExpired() {
    this.refreshSubject.subscribe({
      complete: () => {
        this.refreshSubject = new Subject<any>()
      }
    })
    if (this.refreshSubject.observers.length === 1) {
      from(this.refreshToken()).subscribe(this.refreshSubject)
    }
    return this.refreshSubject
  }

  private checkTokenExpiryErr(error: HttpErrorResponse): boolean {
    return (
      error.status &&
      error.status === 401
    )
  }

  private updateHeader(req) {
    // Get access token
    const accessToken = this.communication.getAccessToken()

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
        return req
    }

    // We clone the request, because the original request is immutable
    return req.clone({
        setHeaders: {
            Authorization: `Bearer ${accessToken}`
        }
    })
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.showLoader()
    return next.handle(req)
      .pipe(tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.onEnd()
          }
        }),
        retry(0),
        catchError( (error, caught) => {
          this.router.navigate(['/login'])
          if (error instanceof HttpErrorResponse) {
            this.onEnd()
            return throwError(error)
          }
          return caught
        }))
  }

  private onEnd(): void {
    this.hideLoader()
  }

  private showLoader(): void {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.loaderService.show()
    }, 300)
  }

  private hideLoader(): void {
    this.loaderService.hide()
    clearTimeout(this.timer)
  }
}
