import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-question-checkboxes',
  templateUrl: './question-checkboxes.component.html',
  styleUrls: ['./question-checkboxes.component.scss']
})
export class QuestionCheckboxesComponent {
  @Input() public parentFormGroup: FormGroup
  @Input() public control: any
  @Input() public small = false
  @Input() public index: number
  @Input() public field: any
  @Input() public isTriggerBoxChecked: boolean
  @Input() public formSubmitAttempt = false
  @Input() public lastQuestion = false
  @Output() public readonly onCheckNoteChanged = new EventEmitter<{ event: any, id: number, index: number, i: number, value: string }>()
  @Output() public readonly onCheckChanged = new EventEmitter<{ event: any, id: number, index: number, i: number }>()
  @Output() public readonly onCheckboxSubfieldChange = new EventEmitter<{ event: any, i: number, trigger: any }>()
  @Output() public readonly questionChangedAlert = new EventEmitter<any>()
  @Output() public readonly scrollToNext = new EventEmitter<any>()
  @Output() public readonly infoToggled = new EventEmitter<any>()
}
