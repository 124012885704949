import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { UtilitiesService } from 'app/services/utilities.service'

@Component({
    selector: 'app-radio-buttons',
    templateUrl: './radio-buttons.component.html',
    styleUrls: ['./radio-buttons.component.scss']
})
export class RadioButtonsComponent implements OnInit {
    @ViewChild('notes', {
      static: false
    }) notes: ElementRef<HTMLInputElement>;
    @Input() public options: any
    @Input() public field: any
    @Input() public forceOneColumn: boolean
    @Input() public class: any
    @Input() public small = false
    @Input() public index: number
    @Input() public parentFormGroup: FormGroup
    @Input() public control: any
    @Output() public readonly onClick = new EventEmitter<any>()
    @Output() public readonly valueChanged = new EventEmitter<any>()
    @Output() public readonly otherChanged = new EventEmitter<any>()
    public showOther = false;
    private controlValue = null

    constructor(private  utilService: UtilitiesService) {
    }

    // on touch devices replace :hover state with touch events
    @HostListener('touchstart', ['$event']) public onTouchStart(event: Event): void {
        const element = event.target as HTMLElement
        element.classList.add('pressed')
    }
    @HostListener('touchend', ['$event']) public onTouchEnd(event: Event): void {
        const element = event.target as HTMLElement
        setTimeout(() => element.classList.remove('pressed'), 50)
    }

    public ngOnInit(): void {
        this.controlValue = this.control.value
        this.showOther = this.field.selected === 'other';
    }

    public onValueChange(event, value) {
        const id = this.options.find(o => o.value === value).id;

        if (id === 'other') {
          this.showOther = true;
        } else {
          this.showOther = false;

          if (this.notes) {
            this.notes.nativeElement.value = "";
          }
        }

        if (this.controlValue !== value && !this.utilService.getQuestionChangedDialog() && (!!this.controlValue || this.controlValue === 0)) {
            this.valueChanged.emit(event)
        } else if (id !== 'other') {
            this.onClick.emit()
        }

        setTimeout(() => {
            this.controlValue = this.control.value
        })

    }

    public onKey(value: string) {
      this.otherChanged.emit({value: value, id: this.field.id});
    }
}
