import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core'

/* Interface */
import { PageInterface } from 'app/interfaces/page.interface'
/* Page Service */
import { PageService } from 'app/services/page.service'
/* Utility Service */
import { UtilitiesService } from 'app/services/utilities.service'
import { logger } from 'app/utils/logger'
import * as _ from 'lodash'

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})

export class PageComponent implements OnChanges {
  private static readonly TAG = 'comp::Page'
  @Input() private selectedPage: PnPage
  @Input() private context: any // Should be PnContext
  @ViewChild('pageComponentWrapper', { read: ViewContainerRef, static: false }) private pageHost: ViewContainerRef
  private activeComponent: any

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private pageService: PageService,
    private utilities: UtilitiesService) {
  }

  public ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.loadComponent()
      const page = document.querySelector('#app-container')
      if (!!page) page.scrollTop = 0
    })
  }

  public loadComponent = (): void => {
    logger.info('%s - loadComponent() %o', PageComponent.TAG, this.selectedPage)
    if (this.pageService.isValid(this.selectedPage)) {
      if (this.activeComponent) {
        this.removeComponent()
      }
      this.addComponent()
    } else if (
      this.selectedPage
      && this.selectedPage.header
      && this.selectedPage.header.type) {
      alert('Uknown page type: ' + this.selectedPage.header.type)
    }
  }

  public addComponent = (): void => {
    const factory: any = this.componentFactoryResolver.resolveComponentFactory(this.pageService.availablePages[this.selectedPage.header.type].component)
    // ------------------------------------------------------------------------------
    // Removing the semicolon from the next line causes an angular error
    //
    // ERROR in src/app/components/page/page.component.ts(63,28): error TS2349:
    // Cannot invoke an expression whose type lacks a call signature.
    // Type 'ComponentRef<unknown>' has no compatible call signatures
    //
    // tslint:disable-next-line: semicolon
    this.activeComponent = this.pageHost.createComponent(factory);
    // tslint:disable-next-line: semicolon
    (this.activeComponent.instance as PageInterface).data = _.cloneDeep(this.selectedPage);
    // tslint:disable-next-line: semicolon
    (this.activeComponent.instance as PageInterface).context = _.cloneDeep(this.context);
    //
    // ------------------------------------------------------------------------------
  }

  public removeComponent = (): void => {
    this.pageHost.remove(0)
    this.activeComponent = null
  }
}
