import { ErrorHandler, Injectable, Injector } from '@angular/core'
import { ErrorService } from './error.service'

// This class replace the default Angular error handler
// every error is dealt with in here

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  public handleError(error) {
    const errorService = this.injector.get(ErrorService)
    errorService.handleError(error)
  }
  }
