import { transition, trigger, useAnimation } from '@angular/animations'
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver,
  ComponentRef, EventEmitter, Input, OnDestroy, Output, Type, ViewChild, ViewEncapsulation } from '@angular/core'
import { ScrollService } from 'app/services/scroll.service'
import { transitions } from 'app/utils/animations'
import { Subject } from 'rxjs'
import { AnalyticsService, Event } from '../../services/analytics'
import { DialogVariant } from './dialog-config'
import { DialogRef } from './dialog-ref'
import { InsertionDirective } from './insertion.directive'

@Component({
  selector: 'app-dialog',
  animations: [
    trigger('overlayEnter', [
      transition(':enter', [ useAnimation(transitions.fadeIn) ]),
      transition(':leave', [ useAnimation(transitions.fadeOut) ])
    ]),
    trigger('modalEnter', [
      transition(':enter', [ useAnimation(transitions.slideIn) ]),
      transition(':leave', [ useAnimation(transitions.slideOut) ])
    ])
  ],
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements AfterViewInit, OnDestroy {

  @Input() public backDrop: boolean = true
  @Input() public title: string
  @Input() public subTitle: string
  @Input() public content: string
  @Input() public doneLabel: string = ''
  @Input() public cancelLabel: string = ''
  @Input() public noPrimary: boolean
  @Input() public withButtons = true
  @Input() public variant: DialogVariant
  @Input() public errorStyle: boolean = false
  @Input() public passive: boolean = false
  @Input() public priority: boolean = false
  @Input() public fixedHeight: boolean = false
  @Output() public readonly extLinkClicked = new EventEmitter<any>()

  private readonly _onClose = new Subject<any>()
  public componentRef: ComponentRef<any>
  public childComponentType: Type<any>
  public onClose = this._onClose.asObservable()
  public isClosing = false
  public first = true
  public dialogVariant = DialogVariant

  @Output() public readonly done: EventEmitter<any> = new EventEmitter<any>()
  @Output() public readonly cancel: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild(InsertionDirective, { static: false }) public insertionPoint: InsertionDirective

  constructor(
    public dialogRef: DialogRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private scroll: ScrollService) { }

  public ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType)
    this.scroll.lock()
    this.cd.detectChanges()
  }

  public ngOnDestroy() {
    this.scroll.unlock()
    if (this.componentRef) {
      this.componentRef.destroy()
    }
  }

  public onDialogClicked(evt: MouseEvent) {
    evt.stopPropagation()
  }

  public loadChildComponent(componentType: Type<any>) {
    if (componentType == null) return
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType)

    const viewContainerRef = this.insertionPoint.viewContainerRef
    viewContainerRef.clear()

    this.componentRef = viewContainerRef.createComponent(componentFactory)
  }

  public animateOut() {
    this.isClosing = true
    return new Promise(resolve => setTimeout(resolve, transitions.durationMs))
  }

  public onContentClick(e) {
    if ((e.target.nodeName.toUpperCase() === 'A')) {
      this.analyticsService.event(Event.ExtLinkOpen, { value1: e.target.href })
    }
  }

}
