import { EventEmitter } from '@angular/core'
import { Observable, Subject } from 'rxjs'

export class DialogRef {
  public done: EventEmitter<any>
  public cancel: EventEmitter<any>

  private readonly _afterClosed = new Subject<any>()
  public afterClosed: Observable<any> = this._afterClosed.asObservable()

  public close(result?: any) {
    this._afterClosed.next(result)
  }
}
