import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ConfirmSignUpComponent } from './components/confirm-sign-up/confirm-sign-up.component'
import { LoginComponent } from './components/login/login.component'
import { OnboardingComponent } from './components/onboarding/onboarding.component'
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component'
import { SelectToolComponent } from './components/select-tool/select-tool.component'
import { SignUpCompleteComponent } from './components/sign-up-complete/sign-up-complete.component'
import { SignUpComponent } from './components/sign-up/sign-up.component'
import { StartConsultationComponent } from './components/start-consultation/start-consultation.component'
import { UserSettingsComponent } from './components/user-settings/user-settings.component'
import { WelcomeComponent } from './components/welcome/welcome.component'
import { GeoBlockGuardService } from './services/guards/geo-block-guard.service'
import { LoginGuardService } from './services/guards/login-guard.service'
import { OnboardingGuardService } from './services/guards/onboarding-guard.service'
import { SelectToolGuardService } from './services/guards/select-tool-guard.service'
import { StartConsultationGuardService } from './services/guards/start-consultation-guard.service'
import { WelcomeGuardService } from './services/guards/welcome-guard.service'
import { AboutComponent } from './components/about/about.component'
import { UserManagementComponent } from './components/user-management/user-management.component'
import { MfaInputComponent } from './components/user-management/mfa-input.component'

export const routes: Routes = [
  {
    path: '',
    component: OnboardingComponent,
    canActivate: [OnboardingGuardService]
  },
  {
    path: 'geoblock-override',
    component: OnboardingComponent,
    canActivate: [GeoBlockGuardService]
  },
  {
    path: 'select-tool',
    component: SelectToolComponent,
    canActivate: [SelectToolGuardService]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuardService]
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [StartConsultationGuardService, WelcomeGuardService]
  },
  {
    path: 'start-consultation',
    component: StartConsultationComponent,
    canActivate: [StartConsultationGuardService]
  },
  {
    path: 'settings',
    component: UserSettingsComponent,
    canActivate: [StartConsultationGuardService]
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [StartConsultationGuardService]
  },
  {
    path: 'mfa-input',
    component: MfaInputComponent,
    canActivate: [StartConsultationGuardService]
  },
  {
    path: 'questionnaire',
    component: QuestionnaireComponent
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    canActivate: [LoginGuardService]
  },
  {
    path: 'confirm',
    component: ConfirmSignUpComponent
  },
  {
    path: 'signup-complete',
    component: SignUpCompleteComponent
  },
  { path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
