import { Component, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { DialogConfig } from 'app/components/dialog/dialog-config'
import { DialogService } from 'app/components/dialog/dialog.service'
import { EmrFormComponent, EmrParameters } from 'app/components/uicomponents/emr-form/emrform.component'

@Component({
  selector: 'app-emr',
  templateUrl: './emr.component.html',
  styleUrls: ['./emr.component.scss']
})
export class EmrComponent {
  @Input() public context: any
  @Input() public title: string

  constructor(
    private dialog: DialogService,
    private translate: TranslateService) {
  }

  public openForm() {
    const config = new DialogConfig<EmrParameters>()
    config.backDrop = true
    config.title = this.title.toUpperCase()
    config.content = this.translate.instant('emr.modal.content', { patientId: this.context.team.patientId })
    config.custom = {
      context: this.context,
      title: this.title
    }
    config.withButtons = false

    this.dialog.open(EmrFormComponent, config)
  }
}
