import { animate, state, style, transition, trigger, useAnimation } from '@angular/animations'
import {
  Component,
  Input,
  OnInit
} from '@angular/core'
import { PageInterface } from 'app/interfaces/page.interface'
import { AnalyticsService, Event } from 'app/services/analytics'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { NavigationService } from 'app/services/navigation.service'
import { ScrollService } from 'app/services/scroll.service'
import { transitions } from 'app/utils/animations'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { logger } from 'app/utils/logger'
import { FilterPipe } from 'w-ng5'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [FilterPipe],
  animations: [FadeInAnimation.animations,
  trigger('selectedItemInOut', [
    transition(':enter', [ useAnimation(transitions.slideInFromBottom) ]),
    transition(':leave', [ useAnimation(transitions.slideOutToBottom) ])
  ]),
  trigger('simpleFadeAnimation', [
    transition(':enter', [ useAnimation(transitions.fadeInImg) ]),
    transition(':leave', [ useAnimation(transitions.fadeOutImg) ])
  ])
]
})

export class SearchComponent implements PageInterface, OnInit {
  private static readonly TAG = 'comp::Search'
  @Input() public data: PnPage
  @Input() public context: any

  // private loading: boolean = true
  private output: any = { }
  public search = ''
  public selected: any = { }
  public stateZero: any = { }
  public stateOne: any = { }
  public toggle = false
  private inhalers: any[] = []
  public totalNumberOfInhalers: number = 0
  public showRecent: boolean = true
  public recentInhalers: any[] = []
  public numberOfSearchResults: number = 0
  private maxRecentInhalers: number = 3
  public affix: boolean = false
  private lastSearch: string

  private defaultPlaceholder = '/assets/inhalers/default.png'

  constructor(
    private navigate: NavigationService,
    private communicate: CommunicationService,
    private pipe: FilterPipe,
    private scroll: ScrollService,
    private localStorage: LocalDataService,
    private analyticsService: AnalyticsService) {
  }

  public ngOnInit() {
    this.recentInhalers = this.getRecent()

    if (this.data.body && this.data.body.fields[0] && this.data.body.fields[0].source) {
      this.communicate.getAnyUrl(this.data.body.fields[0].source)
        .subscribe(
          (data: any): void => {
            this.selectProduct(this.data.body.fields[0].selected)
            this.totalNumberOfInhalers = data.data.length
            this.numberOfSearchResults = data.data.length
            this.inhalers = data.data

            this.fixRecentInhalers()
          }
        )
    }

    this.scroll.onScroll$.subscribe(
      (d: number) => {
        if (window.matchMedia('mx-width:768px').matches) {
          this.affix = d >= 90
        } else {
          this.affix = d >= 40
        }
      }
    )

  }

  public notFound(e: any): void {
    e.preventDefault()
    const { bar } = this.data.navigation
    if (bar && bar.notFound) {
      this.navigate.jumpTo(bar.notFound.direction, { })
      this.analyticsService.event(Event.InhalerNotFound, { value1: this.lastSearch })
    }
  }

  public notAvailable(e: any): void {
    e.preventDefault()
    const { bar } = this.data.navigation
    if (bar && bar.notAvailable) {
      this.navigate.jumpTo(bar.notAvailable.direction, { })
      this.analyticsService.event(Event.InhalerDontKnow)
    }
  }

  public filterProducts = (search: string): any => {
    this.lastSearch = search
    let result: any = []
    if (search && search.length > 0) {
      if (this.showRecent) {
        setTimeout(() => {
          this.showRecent = false
        }, 0)
      }
      result = this.pipe
        .transform(this.inhalers, [{ field: 'name', value: search }, { field: 'standardName', value: search }])
      this.numberOfSearchResults = result.length
      if (result.length === 0) {
        result = [{
          error: true,
          id: 'not-needed-in-this-case'
        }]
      }
      const sortedResult = []
      result.forEach(item => {
        if (item.name && item.name.toLowerCase().includes(search)) {
          return sortedResult.push(
            {
              ...item,
              order: item.name.toLowerCase().indexOf(search)
            })
        }
      })
      return sortedResult.sort((a, b) => a.order < b.order ? -1 : 1)
    }
    if (!this.showRecent) {
      setTimeout(() => {
        this.showRecent = true
      }, 0)
    }
    return this.inhalers
  }

  public selectProduct = (product: any): void => {
    if (this.selected.id && this.selected.id === product.id) {
      this.selected = { }
      delete this.output[this.data.body.fields[0].id]
    } else {
      this.toggle = !this.toggle
      this.toggle ? this.stateZero = product : this.stateOne = product
      this.selected = product
      this.output[this.data.body.fields[0].id] = Object.assign({ }, product)
    }
  }

  public checkImage(url: string): string {
    if (url && url.indexOf('/undefined') < 0 && url.indexOf('/null') < 0 && url.length > 0) return url
    return this.defaultPlaceholder
  }

  private getRecent(): any[] {
    const recentInhalers = this.localStorage.getRecentInhalers() || []
    return recentInhalers.slice(0, this.maxRecentInhalers)
  }

  private setRecent(): void {
    let recentInhalers: any[] = this.recentInhalers.slice(0)
    recentInhalers.unshift(this.selected)
    // Remove duplicates
    recentInhalers = recentInhalers.filter((inhaler, index, self) => self.findIndex(t => t.id === inhaler.id) === index)
    if (recentInhalers.length > this.maxRecentInhalers) {
      recentInhalers.pop()
    }
    this.localStorage.setRecentInhalers(recentInhalers)
  }

  public navigateChange = (): void => {
    this.setRecent()
    this.analyticsService.event(Event.InhalerInLibrary)
    const data = {
      answer: {
        toStepId: this.data.header.id,
        inputs: this.output
      },
      context: null
    }
    this.navigate.nextPage(data)
  }

  // making sure the recent Inhalers match with the downloaded set of inhaler and data is updated
  private fixRecentInhalers() {
    const purifiedInhalers: any[] = []
    try {
      for (const inhaler of this.recentInhalers) {
        const matching = this.inhalers.find(e => e.id === inhaler.id)
        if (matching != null) purifiedInhalers.push(matching)
      }
      this.localStorage.setRecentInhalers(purifiedInhalers)
    } catch (e) {
      // if something went bad, let's clear recent inhalers as fallback
      purifiedInhalers.length = 0
      logger.error('%s - constructor', SearchComponent.TAG)
    }
    this.recentInhalers = purifiedInhalers
    this.localStorage.setRecentInhalers(this.recentInhalers)
  }
}
