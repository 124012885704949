import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { AnalyticsService, Event } from '../../../services/analytics'

@Component({
  selector: 'app-info-dropdown',
  templateUrl: './info-dropdown.component.html',
  styleUrls: ['./info-dropdown.component.scss'],
  animations: [FadeInAnimation.animations]
})
export class InfoDropdownComponent {
  @Output() public readonly toggled: EventEmitter<any> = new EventEmitter()
  @Input() public content: any
  public opened = false

  constructor(private analyticsService: AnalyticsService) {
  }

  public toggleDropdown() {
    this.opened = !this.opened
    this.toggled.emit()
    // need to trigger it 2 times
    // so to have the blurred area
    // animating smoothly while the
    // text expands
    setTimeout(() => {
      this.toggled.emit()
    }, 150)
    setTimeout(() => {
      this.toggled.emit()
    }, 300)

  }

  public onBodyClick(e) {
    if ((e.target.nodeName.toUpperCase() === 'A')) {
      this.analyticsService.event(Event.ExtLinkOpen, { value1: e.target.href })
    }
  }

}
