import { DOCUMENT } from '@angular/common'
import { Inject, Injectable, NgZone } from '@angular/core'
import { BehaviorSubject, fromEvent, Observable } from 'rxjs'
import { map, throttleTime } from 'rxjs/operators'

@Injectable()
export class ScrollService {
  private ONSCROLL$: Observable<any>
  private isLocked = false
  private isBodyLocked = false

  private _scrollLocked = new BehaviorSubject(false)
  public scrollLocked  = this._scrollLocked.asObservable()

  constructor(
    @Inject(DOCUMENT) private document) { }

  private initScrollObserver() {
    this.ONSCROLL$ = fromEvent(window, 'scroll')
        .pipe(
          throttleTime(50),
          map( () => window.pageYOffset)
        )
  }

  // Used to prevent the user from scrolling
  public lock() {
    if (!this.isLocked) {
      this.isLocked = true
      this.document.body.style.overflow = 'hidden'
      this._scrollLocked.next(true)
    }
  }

  public unlock() {
    if (this.isLocked) {
      this.isLocked = false
      this.document.body.style.overflow = 'auto'
      this._scrollLocked.next(false)
    }
  }

  // used to prevent scrolling freezes and glitches
  // when the scrolling happens inside a position fixed
  // element. i.e. on mobile res
  public lockBody() {
    if (!this.isBodyLocked) {
      this.isBodyLocked = true
      this.document.body.style.overflow = 'hidden'
    }
  }

  public unlockBody() {
    if (this.isBodyLocked) {
      this.isBodyLocked = false
      this.document.body.style.overflow = 'auto'
    }
  }

  get onScroll$() {
    if (!this.ONSCROLL$) {
      this.initScrollObserver()
    }
    return this.ONSCROLL$
  }

  public scrollTo(target: HTMLElement, smooth = false) {
    const scrollableParent = (this.isBodyLocked ? document.querySelector('#app-container') : window) as HTMLElement
    const currentScrollAmount  = this.isBodyLocked ? document.querySelector('#app-container').scrollTop : window.pageYOffset
    const navBarHeight = 40 // px, would like to aboid this hardcoded too
    const y = target.getBoundingClientRect().top + currentScrollAmount - navBarHeight
    const ops = { top: y, behavior: 'auto' as ScrollBehavior }
    if (smooth) ops.behavior = 'smooth'
    if (this.isBodyLocked) {
      ops.top -= 50
    }
    scrollableParent.scrollTo(ops)
  }
}
