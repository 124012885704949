const dataStep1 = {
  step: '0',
  fields: { }
 }
const dataStep2 = {
  step: '1',
  fields: {
    role: {
      id: 'role',
      options: [
        {
          id: 'GP',
          label: '',
          value: 'GP'
        },
        {
          id: 'NURSE',
          label: '',
          value: 'NURSE'
        },
        {
          id: 'ALLERGIST',
          label: '',
          value: 'ALLERGIST'
        },
        {
          id: 'PHARMACIST',
          label: '',
          value: 'PHARMACIST'
        },
        {
          id: 'RESPIRATORY_SPEC',
          label: '',
          value: 'RESPIRATORY_SPEC'
        },
        {
          id: 'OTHER_SPEC',
          label: '',
          value: 'OTHER_SPEC'
        }
      ],
      required: true,
      selected: null,
      name: 'role',
      style: 'classic preference',
      type: 'radio',
      value: '',
      validations: {
        required: true
      }
    }
  }
}

const dataStep3 = {
  step: '2',
  fields: {
    guideline: {
      id: 'guideline',
      options: [
        {
          id: 'gina',
          label: 'GINA',
          value: 'GINA'
        },
        {
          id: 'rcp3q',
          label: 'RCP3Q',
          value: 'RCP3Q'
        },
        {
          id: 'acq6',
          label: 'ACQ6',
          value: 'ACQ6'
        }
      ],
      required: true,
      selected: null,
      name: 'guideline',
      style: 'classic preference',
      type: 'radio',
      forceOneColumn: true,
      value: '',
      validations: {
        required: true
      }
    }
  }
}

const dataStep4 = {
  step: '3',
  fields: {
    lungFunction: {
      id: 'lungFunction',
      options: [
        {
          id: 'peakFlow',
          label: 'Peak flow',
          value: 'PEAKFLOW'
        },
        {
          id: 'spirometry',
          label: 'Spirometry',
          value: 'SPIROMETRY'
        }
      ],
      required: true,
      selected: null,
      name: 'lungFunction',
      style: 'classic preference',
      type: 'radio',
      value: '',
      forceOneColumn: true,
      validations: {
        required: true
      }
    }
  }
}

const STEP_ITEMS = [
  {
    data: dataStep1
  },
  {
    data: dataStep2
  },
  {
    data: dataStep3
  },
  {
    data: dataStep4
  },
  {
    data: {
      step: '4',
      fields: { }
    }
  }
]

export { STEP_ITEMS }
