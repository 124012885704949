import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { DialogConfig } from 'app/components/dialog/dialog-config'
import { DialogRef } from 'app/components/dialog/dialog-ref'
import { DialogService } from 'app/components/dialog/dialog.service'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { checkBsnValidator } from 'app/utils/check-bsn'
import { logger } from 'app/utils/logger'
import * as moment from 'moment'
import { AnalyticsService, Event } from '../../../services/analytics'

export interface EmrParameters {
  context: any
  title: string
}

@Component({
  selector: 'app-emrform',
  templateUrl: './emrform.component.html',
  styleUrls: ['./emrform.component.scss']
})
export class EmrFormComponent implements OnInit {
  private static readonly TAG = 'comp::EmrForm'
  public emrForm: FormGroup
  public firstNameFocused = false
  public surNameFocused = false
  public birthDateFocused = false
  public bsnFocused = false
  public edifactAddressFocused = false
  public nameRegex = "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$"

  constructor(
    private fb: FormBuilder,
    public config: DialogConfig<EmrParameters>,
    public dialogref: DialogRef,
    public dialog: DialogService,
    public communication: CommunicationService,
    private analyticsService: AnalyticsService,
    public translate: TranslateService,
    private localData: LocalDataService
  ) {
  }

  public ngOnInit() {
    const edifactAddress = this.localData.getEdifactAddress()
    const patientId = this.config.custom.context.team.patientId
    this.emrForm = this.fb.group({
      firstName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(this.nameRegex)
      ]],
      surName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(this.nameRegex)
      ]],
      birthDate: ['', [
        Validators.required,
        Validators.pattern('^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4}$')
      ]],
      bsn: [patientId, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(9),
        Validators.pattern('^[0-9]{8,9}$'),
        checkBsnValidator
      ]],
      edifactAddress: [edifactAddress, [
        Validators.required,
        Validators.pattern('^[0-9]{8,9}@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5})$')
      ]]
    }, { updateOn: 'blur' })
  }

  public formatDate(bDate: any) {
    const regOpt1 = /^[0-9]{1}[\/]{1}[0-9]{1}[\/]{1}[0-9]{4}$/g
    const regOpt2 = /^[0-9]{1}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4}$/g
    const regOpt3 = /^[0-9]{2}[\/]{1}[0-9]{1}[\/]{1}[0-9]{4}$/g

    if (bDate.target.value.length === 10 || regOpt1.test(bDate.target.value) || regOpt2.test(bDate.target.value)
    || regOpt3.test(bDate.target.value)) {
      let birth = moment(new Date(bDate.target.value)).format('DD/MM/YYYY')
      if (moment(bDate.target.value, 'DD/MM/YYYY').isValid()) {
        birth = moment(bDate.target.value, 'DD/MM/YYYY').format('DD/MM/YYYY')
      }

      this.birthDate.setValue(birth)
    }
  }

  get firstName() {
    return this.emrForm.get('firstName')
  }

  get surName() {
    return this.emrForm.get('surName')
  }

  get birthDate() {
    return this.emrForm.get('birthDate')
  }

  get bsn() {
    return this.emrForm.get('bsn')
  }

  get edifactAddress() {
    return this.emrForm.get('edifactAddress')
  }

  public submitForm() {
    const formValue = this.emrForm.value
    const myJson: any = { }
    myJson.context = this.config.custom.context

    if (!this.emrForm.valid) {
      this.firstName.markAsDirty()
      this.surName.markAsDirty()
      this.birthDate.markAsDirty()
      this.bsn.markAsDirty()
      this.edifactAddress.markAsDirty()
      return
    }

    this.localData.setEdifactAddress(formValue.edifactAddress)
    myJson.submissionInfo = {
      patientName: formValue.firstName,
      patientSurname: formValue.surName,
      patientBSN: formValue.bsn,
      patientDOB: moment.utc(formValue.birthDate, 'DD/MM/YYYY', true),
      hcpEdifactAddress: formValue.edifactAddress
    }

    this.communication.sendEMR(myJson).then(response => {
      this.dialogref.close()
      this.successAlert()
      this.analyticsService.event(Event.emrSubmitSuccess)

      if (response != null && response.length > 0) {
        logger.debug('%s - XML Response: %s', EmrFormComponent.TAG, response)
      }
    }).catch(err => {
      logger.error('%s - Transfer to EMR', EmrFormComponent.TAG, err)
      this.failAlert()
      this.analyticsService.event(Event.emrSubmitFail)
    })
  }

  private successAlert() {
    const config = new DialogConfig<any>()
    config.backDrop = true
    config.title = this.config.custom.title.toUpperCase()
    config.subTitle = this.translate.instant('emr.success.message')
    config.withButtons = false
    this.dialog.open(null, config)
  }

  private failAlert() {
    const config = new DialogConfig<any>()
    config.backDrop = true
    config.title = this.translate.instant('emr.fail.title').toUpperCase()
    config.subTitle = this.translate.instant('emr.fail.message')
    config.withButtons = false
    this.dialog.open(null, config)
  }
}
