import { Component, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-input-error-message',
  templateUrl: './input-error-message.component.html',
  styleUrls: ['./input-error-message.component.scss']
})
export class InputErrorMessageComponent {
  @Input() public field
  @Input() public control
  @Input() public formSubmitAttempt
  @Input() public optionIndex = null

  public genericErrorMessage = this.translate.instant('inputField.error.generic')

  constructor(private translate: TranslateService) {
  }

  // Retrieve the error message received from the API
  // Note: This approach only works if there are NOT multiple validation rules of the same type for one this.field
  public getErrorMessage(type): string {
    if (this.field) {
      switch (type) {
        case 'pattern':
          return this.extractValidationMessage('RegExp')
        case 'min':
        case 'max':
          return this.extractValidationMessage('MinMax')
        default:
          return this.genericErrorMessage
      }
    } else {
      return this.genericErrorMessage
    }
  }

  private extractValidationMessage(type): string {
    const parent = (!!this.optionIndex || this.optionIndex === 0 ) ? this.field.inputs[this.optionIndex] : this.field
    const errorID = parent.validation.findIndex(o => o.condition === type)
    const message = errorID >= 0 ? parent.validation[errorID].message : this.genericErrorMessage
    return !!message ? message : this.genericErrorMessage
  }

}
