import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { ErrorHandler, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { PNTTranslateLoader } from 'app/utils/translate-loader'
import { DynamicModule } from 'ng-dynamic-component'
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha'
import { CookieService } from 'ngx-cookie-service'
import { IConfig, NgxMaskModule } from 'ngx-mask'
import { AppRoutingModule } from './app-routing.module'

/* External Modules */
import { ServiceWorkerModule } from '@angular/service-worker'
import { PipesModule } from 'w-ng5'
import { environment } from '../environments/environment'
/* Components */
import { AppComponent } from './app.component'
import { ConfirmSignUpComponent } from './components/confirm-sign-up/confirm-sign-up.component'
import { DialogComponent } from './components/dialog/dialog.component'
import { InsertionDirective } from './components/dialog/insertion.directive'
import { FirstTimeTourComponent } from './components/first-time-tour/first-time-tour.component'
import { LoginComponent } from './components/login/login.component'
import { MultiStepFormComponent } from './components/multi-step-form/multi-step-form.component'
import { OnboardingComponent } from './components/onboarding/onboarding.component'
import { PageComponent } from './components/page/page.component'
/* Dynamic */
import { AssessmentComponent } from './components/page/pages/assessment/assessment.component'
import { BufferComponent } from './components/page/pages/buffer/buffer.component'
import { ChapterComponent } from './components/page/pages/chapter/chapter.component'
import { GuidanceComponent } from './components/page/pages/guidance/guidance.component'
import { QuestionComponent } from './components/page/pages/question/question.component'
import { ReminderComponent } from './components/page/pages/reminder/reminder.component'
import { SearchComponent } from './components/page/pages/search/search.component'
import { TextComponent } from './components/page/pages/text/text.component'
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component'
import { SelectToolComponent } from './components/select-tool/select-tool.component'
import { SideNavComponent } from './components/side-nav/side-nav.component'
import { SignUpCompleteComponent } from './components/sign-up-complete/sign-up-complete.component'
import { SignUpComponent } from './components/sign-up/sign-up.component'
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component'
import { StartConsultationComponent } from './components/start-consultation/start-consultation.component'
import { AccordionComponent } from './components/uicomponents/accordion/accordion.component'
/* UI */
import { AlertComponent } from './components/uicomponents/alert/alert.component'
import { AnnotationsComponent } from './components/uicomponents/annotations/annotations.component'
import { BackNavigationBarComponent } from './components/uicomponents/back-navigation-bar/back-navigation-bar.component'
import { BackdropComponent } from './components/uicomponents/backdrop/backdrop.component'
import { AppCheckboxLabelDirective, CheckboxComponent } from './components/uicomponents/checkbox/checkbox.component'
import { CookieBannerComponent } from './components/uicomponents/cookie-banner/cookie-banner.component'
import { DisclaimerComponent } from './components/uicomponents/disclaimer/disclaimer.component'
import { EmrFormComponent } from './components/uicomponents/emr-form/emrform.component'
import { EmrComponent } from './components/uicomponents/emr/emr.component'
import { ErrorStateBarComponent } from './components/uicomponents/error-state-bar/error-state-bar.component'
import { HeaderComponent } from './components/uicomponents/header/header.component'
import { IconComponent } from './components/uicomponents/icon/icon.component'
import { InfoDropdownComponent } from './components/uicomponents/info-dropdown/info-dropdown.component'
import { InputErrorMessageComponent } from './components/uicomponents/input-error-message/input-error-message.component'
import { InputNumberComponent } from './components/uicomponents/input-number/input-number.component'
import { InputSelectComponent } from './components/uicomponents/input-select/input-select.component'
import { InputToggleComponent } from './components/uicomponents/input-toggle/input-toggle.component'
import { LoaderComponent } from './components/uicomponents/loader/loader.component'
import { NavigationBarComponent } from './components/uicomponents/navigation-bar/navigationBar.component'
import { PanelComponent } from './components/uicomponents/panel/panel.component'
import { QuestionCheckboxesComponent } from './components/uicomponents/question-checkboxes/question-checkboxes.component'
import { QuestionGenericInputsComponent } from './components/uicomponents/question-generic-inputs/question-generic-inputs.component'
import { QuestionRadioButtonsComponent } from './components/uicomponents/question-radio-buttons/question-radio-buttons.component'
import { QuestionTextareaComponent } from './components/uicomponents/question-textarea/question-textarea.component'
import { QuestionComputeComponent } from './components/uicomponents/question-compute/question-compute.component'
import { RadioButtonsComponent } from './components/uicomponents/radio-buttons/radio-buttons.component'
import { ShapesComponent } from './components/uicomponents/shapes/shapes.component'
import { SideBarComponent } from './components/uicomponents/side-bar/side-bar.component'
import { SlideToggleComponent } from './components/uicomponents/slide-toggle/slide-toggle.component'
import { TagManagerComponent } from './components/uicomponents/tag-manager/tag-manager.component'
import { VersionPanelComponent } from './components/uicomponents/version-panel/version-panel.component'
import { VideoComponent } from './components/uicomponents/video/video.component'
import { UserSettingsComponent } from './components/user-settings/user-settings.component'
import { AboutComponent } from './components/about/about.component'
import { UserManagementComponent } from './components/user-management/user-management.component'
import { VeevaComponent } from './components/veeva/veeva.component'
import { WelcomeComponent } from './components/welcome/welcome.component'
import { LocaleLogoPipe } from './pipes/locale-logo.pipe'
import { SearchFilterPipe } from './pipes/search-filter.pipe'
/* Interfaces */
import { MdPipe } from './pipes/md.pipe'
import { PureMdPipe } from './pipes/pure-md.pipe'
import { SanitizeURLPipe } from './pipes/sanitize-url.pipe'
import { AnalyticsService } from './services/analytics'
/* Services */
import { CommunicationService } from './services/communication.service'
import { GeoBlockGuardService } from './services/guards/geo-block-guard.service'
import { LoginGuardService } from './services/guards/login-guard.service'
import { OnboardingGuardService } from './services/guards/onboarding-guard.service'
import { StartConsultationGuardService } from './services/guards/start-consultation-guard.service'
import { WelcomeGuardService } from './services/guards/welcome-guard.service'
import { NavigationService } from './services/navigation.service'
import { PageService } from './services/page.service'
import { ScrollService } from './services/scroll.service'
import { UtilitiesService } from './services/utilities.service'
import { ErrorService } from './utils/error-handling/error.service'
import { GlobalErrorHandler } from './utils/error-handling/global-error-handler'
import { InterceptorService } from './utils/error-handling/interceptor.service'
import { UserDetailsComponent } from './components/user-management/user-details.component'
import { MfaInputComponent } from './components/user-management/mfa-input.component'

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new PNTTranslateLoader(http)
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null

@NgModule({
  declarations: [
    AppComponent,
    // Modules
    PageComponent,
    LoginComponent,
    QuestionnaireComponent,
    // Dynamic
    BufferComponent,
    ReminderComponent,
    QuestionComponent,
    ChapterComponent,
    SearchComponent,
    // UI
    NavigationBarComponent,
    HeaderComponent,
    AssessmentComponent,
    MdPipe,
    SideNavComponent,
    InfoDropdownComponent,
    TextComponent,
    VersionPanelComponent,
    PureMdPipe,
    BackdropComponent,
    ErrorStateBarComponent,
    BackNavigationBarComponent,
    RadioButtonsComponent,
    AnnotationsComponent,
    AlertComponent,
    IconComponent,
    PanelComponent,
    AccordionComponent,
    CheckboxComponent,
    SanitizeURLPipe,
    VideoComponent,
    OnboardingComponent,
    StartConsultationComponent,
    ShapesComponent,
    GuidanceComponent,
    DialogComponent,
    WelcomeComponent,
    InsertionDirective,
    MultiStepFormComponent,
    UserSettingsComponent,
    AboutComponent,
    UserDetailsComponent,
    UserManagementComponent,
    MfaInputComponent,
    SideBarComponent,
    LoaderComponent,
    SplashScreenComponent,
    AppCheckboxLabelDirective,
    CookieBannerComponent,
    DisclaimerComponent,
    FirstTimeTourComponent,
    SignUpComponent,
    QuestionRadioButtonsComponent,
    QuestionCheckboxesComponent,
    QuestionGenericInputsComponent,
    QuestionComputeComponent,
    QuestionTextareaComponent,
    InputErrorMessageComponent,
    ConfirmSignUpComponent,
    InputToggleComponent,
    InputSelectComponent,
    InputNumberComponent,
    SignUpCompleteComponent,
    SelectToolComponent,
    SlideToggleComponent,
    VeevaComponent,
    LocaleLogoPipe,
    SearchFilterPipe,
    EmrComponent,
    EmrFormComponent,
    TagManagerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    PipesModule,
    ReactiveFormsModule,

    // ngx-translate and the loader module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: PNTTranslateLoader
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    DynamicModule.withComponents([QuestionRadioButtonsComponent,
      QuestionCheckboxesComponent,
      QuestionGenericInputsComponent,
      QuestionComputeComponent,
      QuestionTextareaComponent]),
    NgxMaskModule.forRoot()
  ],
  providers: [
    PageService,
    UtilitiesService,
    CommunicationService,
    NavigationService,
    ScrollService,
    AnalyticsService,
    LoginGuardService,
    OnboardingGuardService,
    GeoBlockGuardService,
    StartConsultationGuardService,
    WelcomeGuardService,
    ErrorService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    CookieService
  ],
  entryComponents: [
    BufferComponent,
    ReminderComponent,
    QuestionComponent,
    DialogComponent,
    ChapterComponent,
    SearchComponent,
    AssessmentComponent,
    GuidanceComponent,
    TextComponent,
    FirstTimeTourComponent,
    QuestionRadioButtonsComponent,
    QuestionCheckboxesComponent,
    QuestionGenericInputsComponent,
    QuestionComputeComponent,
    QuestionTextareaComponent,
    EmrFormComponent,
    UserDetailsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
