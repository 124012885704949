import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { LocalDataService } from 'app/services/local-data.service';
import { environment } from 'environments/environment';
import * as marked from 'marked'

@Pipe({
  name: 'pureMd'
})
export class PureMdPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer,
    private local: LocalDataService) { }

  public transform(value: any, treatment?: string): any {
    if (value && value.length > 0) {
      value = value
      .replace(/%API_HOST%/g, environment.API_HOST)
      .replace(/%ENDPOINT%/g, environment.ENDPOINT)
      value = value.replace(/\*{2}/g, '\n>')
      marked.setOptions({
        breaks: true,
        gfm: true
      })
      let html =  marked(value).trim()
      if (treatment === 'strip') {
        if (html.startsWith('<p>') && html.endsWith('</p>')) {
          html = html.substring(3, html.length - 4)
        }
      }
      return this.sanitizer.bypassSecurityTrustHtml(html)
    }
    return value
  }

}
