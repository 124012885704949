import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { CommunicationService } from 'app/services/communication.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { logger } from 'app/utils/logger'
import { environment } from 'environments/environment'
import { RecaptchaComponent } from 'ng-recaptcha'
import { Subscription } from 'rxjs'
import { NavBackBarVariant } from '../uicomponents/back-navigation-bar/back-navigation-bar.component'

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: FadeInAnimation.animations
})
export class SignUpComponent implements OnInit, OnDestroy {
  private static readonly TAG = 'comp::SignUp'

  public navBackBarVariant = NavBackBarVariant
  // CODEREVIEW why this? why not having a separate component and route?
  public activeStepIndex = 1
  public signUpForm: FormGroup
  public formSubmitAttempt = false
  public nameInputFocus = false
  public lastNameInputFocus = false
  public emailInputFocus = false
  public siteKey = environment.reCaptcha.siteKey
  private readonly roles = ['GP', 'NURSE', 'ALLERGIST', 'PHARMACIST', 'RESPIRATORY_SPEC', 'OTHER_SPEC']
  public rolesOptions = []
  @ViewChild('recaptchaRef', { static: false }) public recaptchaRef: RecaptchaComponent

  private subscriptions = new Subscription()

  constructor(
    private fb: FormBuilder,
    private communicate: CommunicationService,
    private router: Router,
    private translate: TranslateService) {

  }

  public ngOnInit() {
    logger.debug('%s - ngOnInit()', SignUpComponent.TAG)
    const keys = this.roles.map(e => `signUp.steps_1.role.${e}`)
    const stringsSubscription = this.translate.get(keys).subscribe(values => {
      this.rolesOptions = this.roles.map(e => ({
        id: e,
        value: e,
        label: this.translate.instant(`signUp.steps_1.role.${e}`)
      }))
    })
    this.subscriptions.add(stringsSubscription)
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    this.signUpForm = this.fb.group({
      name: ['', [
        Validators.required
      ]],
      lastname: ['', [
        Validators.required
      ]],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern(emailRegex)
      ]],
      role: ['GP', [
        Validators.required
      ]]
    })
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  public goToStep(step: string): void {
    this.router.navigate(['login'])
  }

  public goToCompleteRegistration(): void {
    this.router.navigate(['confirm'], { queryParams: { user: this.signUpForm.controls.email.value } })
  }

  public submitSignUp(token: string) {
    const formValue = this.signUpForm.value
    const request: SignUpRequest = {
      name: formValue.name,
      lastname: formValue.lastname,
      email: formValue.email,
      role: formValue.role,
      token
    }
    this.subscriptions.add(this.communicate.signUp(request).subscribe(() => {
      this.activeStepIndex = 2
    }))
  }

  public submitClicked() {
    if (this.signUpForm.valid) {
      this.activeStepIndex === 1 ? this.recaptchaRef.execute() : this.goToStep('next')
    } else {
      this.formSubmitAttempt = true
    }
  }
}
