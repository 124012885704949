import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent {
  @Input() public customClass: string = ''
  @Input() public languageLabel: string
  @Output() public readonly onToggle: EventEmitter<any> = new EventEmitter<any>()

  public onToggled(e) {
    this.onToggle.emit(e)
  }
}
