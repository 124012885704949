import { HttpErrorResponse } from '@angular/common/http'
import { Injectable, Injector, NgZone, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { DialogConfig } from 'app/components/dialog/dialog-config'
import { DialogRef } from 'app/components/dialog/dialog-ref'
import { DialogService } from 'app/components/dialog/dialog.service'
import { AnalyticsService, Event } from 'app/services/analytics'
import { LoaderService } from 'app/services/loader.service'
import { NavigationService } from 'app/services/navigation.service'
import { environment } from 'environments/environment'
import { fromEvent, merge, Observable, Observer, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { logger } from '../logger'

// This service is to open up the error dialog
// It is separated from global-error-handler so
// that it can be used outside of it as well.
// i.e. with the good ol' try catch which would
// override global-error-handler behaviour

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements OnDestroy {
  private isOnline = true
  private unsubscribe$ = new Subject()
  private errorDialog: DialogRef = null
  private dialogIsOpen = false

  constructor(private ngZone: NgZone,
              private analyticsService: AnalyticsService,
              private translate: TranslateService,
              private navigation: NavigationService,
              private injector: Injector,
              public dialog: DialogService,
              private loaderService: LoaderService) {
  }

  public async handleError(error) {
    let message
    if (error.rejection) {
      message = await this.getServerErrorMessage(error.rejection)
    } else if (error instanceof HttpErrorResponse) {
      // Server error
      message = await this.getServerErrorMessage(error)
    } else {
      // Client Error
      message = this.getClientErrorMessage(error)
    }
    if (!this.dialogIsOpen && message) this.openErrorModal(message)
    logger.error(message, error)
  }

  public openErrorModal(message: any) {
    this.ngZone.run(() => {
      this.dialogIsOpen = true
      const config = new DialogConfig<any>()
      config.title = this.translate.instant('errorDialog.title')
      config.content = message
      config.withButtons = false
      this.errorDialog = this.dialog.openError(null, config)
      this.errorDialog.afterClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(e => {
          this.dialogIsOpen = false
        })
    })
  }

  private getClientErrorMessage(error): string {
    if (!environment.production) {
      return error.customMessage ? error.message + '\n' + error.customMessage  : error.toString()
    }
    return error.customMessage ? error.customMessage : this.translate.instant('errorDialog.clientError')
  }

  private async getServerErrorMessage(error: HttpErrorResponse) {
    const router = this.injector.get(Router)

    if (!navigator.onLine && !this.dialogIsOpen) {
      this.createOnline$().pipe(takeUntil(this.unsubscribe$))
        .subscribe(isOnline => {
          this.isOnline = isOnline
          if (isOnline) {
            this.ngZone.run(() => {
              this.errorDialog.close()
              this.unsubscribe$.next()
              this.unsubscribe$.complete()
            })
          }
        })
      return this.translate.instant('errorDialog.noNetwork')
    } else if (error.status === 0) {
      return this.translate.instant('errorDialog.noNetwork')
    } else if (error.status >= 400 && error.status < 500) {
      if (error.status === 403) {
        this.analyticsService.event(Event.AppGeoblocked, { value1: JSON.stringify(error) })
        this.openGeoblockinModal(error)
        return null
      } else if (error.status === 401) {
        this.navigation.logout()
        await router.navigate(['/login'])
        if (!environment.production) {
            return error.message + '\n' + error.error.message
        }
        return this.translate.instant('error.401')
      }  else if (error.status === 409) {
        this.analyticsService.event(Event.AppErr, { value1: JSON.stringify(error) })
        {
          if (!environment.production) {
            return error.message + '\n' + error.error.message
          }
          return this.translate.instant('errorDialog.conflict')
        }
      }  else if (error.status === 410) {
        await router.navigate(['/mfa-input']);
        return;
      }
      this.analyticsService.event(Event.SysErr, { value1: JSON.stringify(error) })
      if (!environment.production) {
        return error.message + '\n' + error.error.message
      }
      return this.translate.instant('errorDialog.serverError')
    } else if (error.status >= 500) {
      this.analyticsService.event(Event.AppErr, { value1: JSON.stringify(error) })
      {
        if (!environment.production) {
          return error.message + '\n' + error.error.message
        }
        return this.translate.instant('errorDialog.serverError')
      }
    } else {
      this.analyticsService.event(Event.SysErr, { value1: JSON.stringify(error) })
      if (!environment.production) {
        return error.message
      }
      return this.translate.instant('errorDialog.unknownError')
    }
  }

  public createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine)
        sub.complete()
      }))
  }

  private openGeoblockinModal(error) {
    this.ngZone.run(() => {
      this.dialogIsOpen = true
      const list = error.error.extras.localisedAllowedCountries.map((country, i) => {
        return i !== 0 ? ' ' + country.label : country.label
      })
      const config = new DialogConfig<any>()
      config.title = this.translate.instant('errorDialog.geoblock.title')
      config.subTitle = this.translate.instant('errorDialog.geoblock.subTitle')
      config.content = this.translate.instant('errorDialog.geoblock.content', { countries: list })
      config.withButtons = false
      this.errorDialog = this.dialog.open(null, config)
      this.errorDialog.afterClosed
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(e => {
                this.dialogIsOpen = false
              })
    })
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
