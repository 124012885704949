import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() public name: string
  @Input() public size: string
  @Input() public color: string = 'black'
  public path

  public ngOnInit() {
    this.path = 'assets/icons.svg#' + this.name
  }
}
