import {
  Component,
  Input
} from '@angular/core'

import { PageInterface } from 'app/interfaces/page.interface'
import { NavigationService } from 'app/services/navigation.service'

@Component({
  selector: 'app-buffer',
  templateUrl: './buffer.component.html'
})

export class BufferComponent implements PageInterface {
  @Input() public data: PnPage
  @Input() public context: any // Should be PnContext

  public loading: boolean = false

  constructor(
    private navigate: NavigationService
  ) { }

  public navigateChange = (to: any): void => {
    this.loading = true
    const data = {
      answer: {
        toStepId: this.data.header.id
      },
      context: null
    }
    if ( to.direction === 'next' ) this.navigate.nextPage(data)
  }
}
