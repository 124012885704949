import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    HostListener,
    Injector,
    OnDestroy,
    OnInit
} from '@angular/core'
import { NavigationStart } from '@angular/router'
import { Event as NavigationEvent } from '@angular/router'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { logger } from 'app/utils/logger'
import { environment } from 'environments/environment'
import * as _ from 'lodash'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { AnalyticsService, Event } from './services/analytics'
import { LocalDataService } from './services/local-data.service'
import { NavigationService } from './services/navigation.service'
import { ScrollService } from './services/scroll.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private static readonly TAG = 'comp::App'
  private static readonly SCROLL_LOCK_THRESHOLD = 1024
  public title = 'az-patient-navigator-pwa'
  public environment = environment
  public scrollIsLocked = false
  private unsubscribe$ = new Subject()

  constructor(
    private translate: TranslateService,
    private navigate: NavigationService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private injector: Injector,
    private cdr: ChangeDetectorRef,
    private localData: LocalDataService
  ) {
    logger.info('%s - constructor', AppComponent.TAG)
  }

  @HostListener('window:resize', []) public onResize() {
    this.updateScrollLock()
  }

  private updateScrollLock(): ScrollService {
    const scrollService = this.injector.get(ScrollService)
    if (window.innerWidth <= AppComponent.SCROLL_LOCK_THRESHOLD) {
      scrollService.lockBody()
    } else {
      scrollService.unlockBody()
    }
    return scrollService
  }

  public ngAfterViewInit(): void {
    this.updateScrollLock().scrollLocked
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(locked => {
        this.scrollIsLocked = locked
        this.cdr.detectChanges()
      })

  }

  public ngOnInit() {
    const { country, language } = this.localData.getLocale()
    // Documentation for multi-language implementation
    // https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular8-app-with-ngx-translate
    try {
      if (language !== null && country === null) {
        this.translate.setDefaultLang(language)
        this.translate.use(language)
      } else {
        this.translate.setDefaultLang('en')
        this.translate.use('en')
      }
    } catch {
        this.translate.setDefaultLang('empty')
        this.translate.use('en')
    }

    // Router
    this.router.events.pipe(
      // The "events" stream contains all the navigation events. For this demo,
      // though, we only care about the NavigationStart event as it contains
      // information about what initiated the navigation sequence.
      filter((event: NavigationEvent) => (event instanceof NavigationStart))
    ).subscribe(
      (event: NavigationStart) => {
        const browserRefresh = !this.router.navigated
        if (browserRefresh && event.url === '/questionnaire') {
          // this works as a canActivateGuard too
          this.router.navigate(['/start-consultation'])
          return
        }

        const exitingQuestionnaire = _.get(event, 'restoredState.sessionStart')
        // Manage Back and Next browser buttons
        if (event.url !== '/questionnaire' && exitingQuestionnaire && event.navigationTrigger !== 'imperative') {
          this.analyticsService.event(Event.BrowserNavAttempt)
          if (confirm(this.translate.instant('leaveDialog.message'))) {
            this.analyticsService.event(Event.ConsultationAbort)
            this.navigate.logout()
            // history.pushState(null, this.navigate.getTitle(), '/start-consultation')
            this.router.navigate(['start-consultation'])
          } else {
            history.pushState(null, this.navigate.getTitle(), '/questionnaire')
            this.router.navigate(['questionnaire'])
            this.navigate.reloadContent()
          }
        }
      }
    )
  }

  public onActivate() {
      setTimeout(() => {
          window.scrollTo(0, 0)
          const page = document.querySelector('#app-container')
          if (!!page) page.scrollTop = 0
      }, 0)
  }

  public ngOnDestroy(): void {
    const scrollService = this.injector.get(ScrollService)
    scrollService.unlockBody()
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

}
