import { Component } from '@angular/core'
import { CommunicationService } from 'app/services/communication.service'
import { LocalDataService } from 'app/services/local-data.service'
import { UtilitiesService } from 'app/services/utilities.service'
import { FadeInAnimation } from 'app/utils/fade-in-animation'
import { STEP_ITEMS } from './multiple.form.data'

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  animations: FadeInAnimation.animations
})
export class WelcomeComponent {

  public formData: any = STEP_ITEMS
  public formIndex: number = 0

  constructor(private communicate: CommunicationService,
              private localData: LocalDataService,
              private utilService: UtilitiesService) {
  }

  public updateFormIndex(index) {
    this.formIndex = index
  }

  public onSubmit(formData) {
    const data = {
      language: this.localData.getLocale().language,
      guideline: formData.guideline,
      lungFunction: formData.lungFunction,
      role: formData.role
    }
    this.communicate.putSettings(data).subscribe(response => {
      this.utilService.clearWelcomeActive()
      this.updateFormIndex(this.formIndex + 1)
    })
  }
}
