import { Component, EventEmitter, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LocalDataService } from 'app/services/local-data.service'

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent {
  @Output() public readonly onCookiesAccepted = new EventEmitter<any>()

  constructor(
    private translate: TranslateService,
    private localData: LocalDataService) { }

  public onCloseBanner() {
    this.onCookiesAccepted.emit()
  }

  public getContent(): string {
    const locale = this.localData.getLocale()
    const cookieURL = `/assets/cookies/${locale.language}-${locale.country}.html`
    return this.translate.instant('cookieBanner.body1', { cookieURL })
  }
}
